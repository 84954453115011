import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useParams } from "react-router-dom";

import { CACHED_CATALOG_STALE_TIME, EMPTY_CATALOG_ERROR_CODE } from "src/config/constants";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { OfflineCatalogError } from "src/models";
import { s2sDatabase } from "src/objectStorage";
import {
  ApiError,
  LiteOfflineCatalogControllerService,
  LiteOrderTypeConfiguration,
} from "src/typegens";
import {
  flattenCategoryTree,
  getCompositeStoredCatalogCategoryId,
  getCompositeStoredCatalogId,
  isApiError,
  isOfflineCatalogError,
} from "src/utils";

export const OFFLINE_CATALOG_QUERY_KEY_BASE = "offlineCatalog";

const getQueryKey = ({
  isOnline,
  portId,
  orderType,
  categoryId,
  supplierId,
  hasSelectSupplierFeature,
}: {
  isOnline: boolean;
  portId: string | undefined;
  orderType: LiteOrderTypeConfiguration["type"] | undefined;
  categoryId?: string;
  supplierId: string | undefined;
  hasSelectSupplierFeature: boolean;
}) =>
  hasSelectSupplierFeature
    ? [OFFLINE_CATALOG_QUERY_KEY_BASE, isOnline, orderType, portId, supplierId, categoryId]
    : [OFFLINE_CATALOG_QUERY_KEY_BASE, isOnline, orderType, portId, categoryId];

export const useOfflineCatalogQueryHelpers = ({
  portId,
  orderType,
  categoryId: categoryIdParam,
  supplierId,
}: {
  portId: string;
  orderType: LiteOrderTypeConfiguration["type"] | undefined;
  categoryId: string | undefined;
  supplierId: string | undefined;
}) => {
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const queryClient = useQueryClient();
  const { isOnline } = useNetworkDetector();
  const { categoryId } = useParams();
  const queryKey = getQueryKey({
    isOnline,
    portId,
    orderType,
    categoryId: categoryIdParam || categoryId,
    supplierId,
    hasSelectSupplierFeature,
  });
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const offlineCatalogQuery = queryClient.getQueryState<{ hasCatalogData: boolean }>(queryKey);

  return {
    isDownloadingOfflineCatalog:
      areOfflineCapabilitiesEnabled &&
      (offlineCatalogQuery?.status === "pending" ||
        offlineCatalogQuery?.fetchStatus === "fetching"),
    hasOfflineCatalogData: !!offlineCatalogQuery?.data?.hasCatalogData,
  };
};

export const useOfflineCatalogQuery = ({
  orderType: orderTypeParam,
  portId: portIdParam,
  categoryId,
  disabled = false,
  supplierId: supplierIdParam,
}: {
  portId: string | undefined;
  orderType: LiteOrderTypeConfiguration["type"] | undefined;
  disabled?: boolean;
  categoryId?: string;
  supplierId: string | undefined;
}) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();
  const hasSelectSupplierFeature = useFlag("select-supplier");

  return useQuery<{ hasCatalogData: boolean }, ApiError>({
    queryKey: getQueryKey({
      isOnline,
      portId: portIdParam,
      orderType: orderTypeParam,
      categoryId,
      supplierId: supplierIdParam,
      hasSelectSupplierFeature,
    }),
    queryFn: async ({ signal }) => {
      const orderType = orderTypeParam || "DEFAULT";
      const portId = portIdParam || "";
      const supplierId = supplierIdParam || "";
      const categories = await s2sDatabase.categoriesByPortId.get(
        getCompositeStoredCatalogId({ portId, orderType, supplierId })
      );
      const isCatalogCached = !!(categories && !!categories?.updatedAt);
      const diff = categories ? Date.now() - new Date(categories?.updatedAt).valueOf() : 0;
      const isCatalogDataStale = diff > CACHED_CATALOG_STALE_TIME;

      if (!isOnline) {
        return { hasCatalogData: isCatalogCached };
      }

      if (isCatalogCached && !isCatalogDataStale) {
        return { hasCatalogData: true };
      }

      try {
        const response = await LiteOfflineCatalogControllerService.getOfflineCatalog(
          {
            portId,
            lastUpdatedAt: categories?.updatedAt,
            orderType,
            supplierId: hasSelectSupplierFeature ? supplierId : undefined,
          },
          { signal }
        );

        if (response.categoryTree.length === 0) {
          throw new OfflineCatalogError("Empty catalog response", EMPTY_CATALOG_ERROR_CODE);
        }

        // TODO #11950: Proper transaction to get categories by order type by port by supplier
        await s2sDatabase.transaction(
          "rw",
          s2sDatabase.products,
          s2sDatabase.productsByPortId,
          // TS complains about recursive type in stores below,
          // because of that we couldn't reference them by object, but by string which works fine in runtime
          "categoriesByPortId",
          "categories",
          async () => {
            const productIds: string[] = [];
            const flatCategories = flattenCategoryTree(response.categoryTree).map((category) => {
              return {
                ...category,
                categoryPortId: getCompositeStoredCatalogCategoryId({
                  orderType,
                  portId,
                  categoryId: category.id,
                  supplierId,
                }),
                categoryId: category.id,
                portId,
                orderType,
              };
            });

            for (const category of flatCategories) {
              s2sDatabase.categories.put(category);

              for (const product of category.products) {
                s2sDatabase.products.put(product);
                productIds.push(product.id);
              }
            }

            await s2sDatabase.categoriesByPortId.put({
              items: response.categoryTree,
              portId: getCompositeStoredCatalogId({ portId, orderType, supplierId }),
              orderType,
              updatedAt: new Date().toISOString(),
            });

            await s2sDatabase.productsByPortId.put({
              productIds: [...new Set(productIds)],
              portId: getCompositeStoredCatalogId({ portId, orderType, supplierId }),
            });
          }
        );
        return { hasCatalogData: true };
      } catch (error) {
        if (isApiError(error)) {
          // 304 status indicates that there were no modifications to catalog since last time it was fetched.
          // In such case we return indication that we have catalog data, otherwise re-throw original error
          if ((error as ApiError).status === 304) {
            return { hasCatalogData: true };
          }

          // If cached data is available use it as a fallback,
          // otherwise re-throw API error
          if (isCatalogCached) {
            return { hasCatalogData: true };
          }

          throw new ApiError(
            error.request,
            {
              ok: false,
              url: error.url,
              status: error.status,
              statusText: error.statusText,
              body: error.body,
            },
            error.message
          );
        }

        if (isOfflineCatalogError(error)) {
          // We need to re-throw it, otherwise it won't reach ErrorBoundary
          throw new OfflineCatalogError(error.message, error.status);
        }

        throw new Error((error as Error)?.message);
      }
    },
    enabled:
      areOfflineCapabilitiesEnabled &&
      !!portIdParam &&
      !!orderTypeParam &&
      (hasSelectSupplierFeature ? !!supplierIdParam : true) &&
      !disabled,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });
};
