import { IDB_COMPOSITE_KEYS_SEPARATOR } from "../config/constants";
import { OrderStocktakeType } from "../models";

export const LEGACY_getCompositeStoredCatalogId = ({
  portId,
  orderType,
  supplierId,
}: {
  portId?: string;
  orderType?: OrderStocktakeType;
  supplierId?: string;
}): string => {
  if (!portId) {
    return "";
  }

  return `${portId}${IDB_COMPOSITE_KEYS_SEPARATOR}${
    orderType || "DEFAULT"
  }${IDB_COMPOSITE_KEYS_SEPARATOR}${supplierId || ""}`;
};

export const getCompositeStoredCatalogId = ({
  orderType,
  portId,
  supplierId,
}: {
  orderType: OrderStocktakeType;
  portId: string;
  supplierId: string;
}): string =>
  `${portId}${IDB_COMPOSITE_KEYS_SEPARATOR}${orderType}${IDB_COMPOSITE_KEYS_SEPARATOR}${supplierId}`;

export const getCompositeStoredCatalogCategoryId = ({
  orderType,
  portId,
  supplierId,
  categoryId,
}: {
  orderType: OrderStocktakeType;
  portId: string;
  supplierId: string;
  categoryId: string;
}): string =>
  `${categoryId}${IDB_COMPOSITE_KEYS_SEPARATOR}${portId}${IDB_COMPOSITE_KEYS_SEPARATOR}${orderType}${IDB_COMPOSITE_KEYS_SEPARATOR}${supplierId}`;
