import { useFlag } from "@unleash/proxy-client-react";
import { useCallback } from "react";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { CXmlPunchoutService, LiteRequisitionCreationCatalogItem } from "src/typegens";

import { usePunchOutIntegration } from "./usePunchOutIntegration";

export const usePunchoutOrder = ({
  catalogItems,
}: {
  catalogItems: LiteRequisitionCreationCatalogItem[];
}) => {
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const [{ port, configuration, supplier }] = useAppStateContext();
  const { supplierId: punchoutSupplierId, punchoutSessionId } = usePunchOutIntegration();
  const supplierId = hasSelectSupplierFeature ? supplier?.id : punchoutSupplierId;
  return useCallback(async () => {
    return CXmlPunchoutService.sendOrderMessage({
      requestBody: {
        portId: port?.id || "",
        vesselId: configuration?.vessel.id || "",
        supplierId: supplierId || "",
        sessionId: punchoutSessionId || "",
        catalogItems,
      },
    });
  }, [catalogItems, configuration?.vessel.id, punchoutSessionId, port?.id, supplierId]);
};
