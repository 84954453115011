import { z } from "zod";

import { LiteSkuGeneralInformationSchema } from "./LiteSkuGeneralInformationSchema";
import { LiteSkuQualitySchema } from "./LiteSkuQualitySchema";
import { nonEmptyString, optionalNonEmptyString } from "./common";

export const LiteSkuAboutSchema = z.object({
  name: nonEmptyString(),
  description: optionalNonEmptyString(),
  generalInformation: LiteSkuGeneralInformationSchema.optional(),
  quality: LiteSkuQualitySchema.optional(),
});
