import { useFlag } from "@unleash/proxy-client-react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import { useSpinDelay } from "spin-delay";

import { getNonEmptyCategories } from "@web/common";
import { Loading } from "@web/ui";
import { isDefined } from "@web/utils";

import { OfflineCatalogLoader } from "src/components/OfflineCatalogLoader";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineCatalogQuery } from "src/hooks/useOfflineCatalogQuery";
import { usePunchOutIntegration } from "src/hooks/usePunchOutIntegration";

import { NoCachedData } from "../../components/NoCachedData";
import { RoutesConfig } from "../../config/routes";
import { useAppStateContext } from "../../contexts/AppStateContext";
import useCategories from "../../hooks/useCategories";
import { useNoCachedQueryGuard } from "../../hooks/useNoCachedQueryGuard";
import { usePunchoutBasketQuery } from "../../hooks/usePunchoutBasketQuery";
import { LocalProductService } from "../../services/LocalProductService";
import { LEGACY_PreconfigureOrder, PreconfigureOrder } from "../PreconfigureOrder";

export const GatherSetup: React.FC = () => {
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const location = useLocation();
  const [{ port, forcePreconfigureOrderSetup, orderType, supplier }, dispatch] =
    useAppStateContext();
  const { punchoutSessionId, supplierId } = usePunchOutIntegration();
  const navigate = useNavigate();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const offlineCatalogQuery = useOfflineCatalogQuery({
    orderType,
    portId: port?.id,
    supplierId: supplier?.id,
    disabled: !!forcePreconfigureOrderSetup,
  });
  const shouldDisableCategoriesQuery =
    forcePreconfigureOrderSetup ||
    offlineCatalogQuery.isFetching ||
    (areOfflineCapabilitiesEnabled && !offlineCatalogQuery.data?.hasCatalogData);
  const shouldDisplayOfflineCatalogLoader = useSpinDelay(offlineCatalogQuery.isFetching, {
    delay: 800,
    ssr: false,
  });

  const {
    data: punchoutBasket,
    isSuccess: isPunchoutBasketSuccess,
    isPending: isPunchoutBasketPending,
  } = usePunchoutBasketQuery();

  const [wasPunchoutSet, setWasPunchoutSet] = useState(false);

  const { categoriesQuery, invalidateQuery } = useCategories({
    disabled: shouldDisableCategoriesQuery,
  });

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryPending: categoriesQuery.isPending,
    isQueryError:
      categoriesQuery.isError ||
      (isDefined(offlineCatalogQuery.data) && !offlineCatalogQuery.data.hasCatalogData),
    becameOnlineCallback: invalidateQuery,
  });

  ReactGA.ga("send", "pageview", location.pathname);

  // In case of punchout, check if we are in a repunchout flow
  useEffect(() => {
    if (!isPunchoutBasketSuccess) {
      localStorage.removeItem("repunchout");
    } else if (punchoutBasket.port && supplierId && !wasPunchoutSet) {
      setWasPunchoutSet(true);

      const punchoutBasketStoredString =
        LocalProductService.convertPunchoutBasketToStorageString(punchoutBasket);

      localStorage.setItem("repunchout", punchoutBasketStoredString);

      if (hasSelectSupplierFeature) {
        dispatch({
          type: "setRepunchoutSetup",
          value: {
            // Punchout does not support different order types, so it's `DEFAULT` every time
            orderType: "DEFAULT",
            portId: punchoutBasket.port.id,
            supplierId,
          },
        });
      } else {
        dispatch({
          type: "LEGACY_setRepunchoutSetup",
          value: {
            // Punchout does not support different order types, so it's `DEFAULT` every time
            orderType: "DEFAULT",
            port: punchoutBasket.port,
          },
        });
      }
    }
  }, [
    dispatch,
    hasSelectSupplierFeature,
    isPunchoutBasketSuccess,
    punchoutBasket,
    setWasPunchoutSet,
    supplierId,
    wasPunchoutSet,
  ]);

  // If the categories are loaded, navigate to the catalog
  useEffect(() => {
    if (
      !shouldDisableCategoriesQuery &&
      categoriesQuery.data !== undefined &&
      categoriesQuery.data.length > 0
    ) {
      const categoriesWithProducts = getNonEmptyCategories(categoriesQuery.data);

      if (categoriesWithProducts.length > 0) {
        navigate(`${RoutesConfig.category}/${categoriesWithProducts[0].id}`);
      } else {
        navigate(`${RoutesConfig.category}/${categoriesQuery.data[0].id}`);
      }
    }
  }, [categoriesQuery.data, navigate, shouldDisableCategoriesQuery]);

  // In case of punchout, wait until the punchout basket is loaded so there's
  // no flicker in repunchout flow
  if (punchoutSessionId && isPunchoutBasketPending) {
    return <Loading />;
  }

  if (forcePreconfigureOrderSetup) {
    return hasSelectSupplierFeature ? <PreconfigureOrder /> : <LEGACY_PreconfigureOrder />;
  }

  // Properly support no cached data in offline flows
  if (hasNoCachedData) {
    return <NoCachedData hasGoBack={true} />;
  }

  if (shouldDisplayOfflineCatalogLoader) {
    return <OfflineCatalogLoader />;
  }

  // Wait for categories if they are actually fetching + keep showing loading after success until we navigate away
  if (categoriesQuery.isFetching || categoriesQuery.isSuccess) {
    return <Loading />;
  }

  // In all other cases show the preconfigure order page
  return hasSelectSupplierFeature ? <PreconfigureOrder /> : <LEGACY_PreconfigureOrder />;
};
