import { useFlag } from "@unleash/proxy-client-react";
import { ChangeEvent, FormEvent, useState } from "react";
import ReactGA from "react-ga4";

import { flattenPorts, trackingCategories, trackingEvents, useSearchHelpers } from "@web/common";

import { BrowseBar } from "src/components/BrowseBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useCategories from "src/hooks/useCategories";
import { useOfflineCatalogQueryHelpers } from "src/hooks/useOfflineCatalogQuery";
import { LiteCategory } from "src/typegens";

type GatherControlBarProps = {
  searchRoute: string;
};

export const GatherControlBar: React.FC<GatherControlBarProps> = ({ searchRoute }) => {
  const { query, setQuery } = useSearchHelpers(searchRoute);
  const [typedQuery, setTypedQuery] = useState(query);
  const [{ port, configuration, orderType, supplier }] = useAppStateContext();
  const { isOnline } = useNetworkDetector();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const isOfflineSearchEnabled =
    useFlag("offline-products-search") && areOfflineCapabilitiesEnabled;

  const setCategoryCallback = (category: LiteCategory) => {
    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.CATEGORY_TAB_CHANGED,
      label: category.name,
    });
  };

  const onToggleCallback = (state: boolean) => {
    if (state) {
      ReactGA.event({
        category: trackingCategories.CREW_APP,
        action: trackingEvents.CATEGORY_DROPDOWN_ACTIVATED,
      });
    }
  };

  const { isDownloadingOfflineCatalog, hasOfflineCatalogData } = useOfflineCatalogQueryHelpers({
    portId: port?.id || "",
    orderType,
    categoryId: undefined,
    supplierId: supplier?.id,
  });
  const { categoriesQuery } = useCategories({
    disabled:
      isDownloadingOfflineCatalog || (areOfflineCapabilitiesEnabled && !hasOfflineCatalogData),
  });

  if (categoriesQuery.status !== "success") {
    return null;
  }

  if (!configuration) {
    return null;
  }

  const ports = flattenPorts(configuration.ports);
  const selectedPort = ports.find((p) => p.id === port?.id);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTypedQuery(e.target.value);
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (typedQuery.length > 0) {
      setQuery(typedQuery);
    }
  };

  return (
    <BrowseBar
      selectedPort={selectedPort}
      categorySelectorSettings={{
        categories: categoriesQuery.data,
        onSelectToggle: onToggleCallback,
        onCategoryChange: setCategoryCallback,
        subCategoryRoute: RoutesConfig.category,
      }}
      searchBarSettings={{
        placeholder: "Search...",
        onSubmit: handleSearchSubmit,
        onQueryChange: handleQueryChange,
        query: typedQuery,
        isOnline: areOfflineCapabilitiesEnabled ? isOnline : true,
        isOfflineSearchEnabled,
      }}
    />
  );
};
