import { matchSorter } from "match-sorter";
import { useState } from "react";

export const useFuzzySearch = <T>({ collection, keys }: { collection: T[]; keys: string[] }) => {
  const [filteredCollection, setFilteredCollection] = useState(collection);

  const handleSearchPatternChange = (pattern: string) => {
    if (pattern === "") {
      setFilteredCollection(collection);
    } else {
      setFilteredCollection(matchSorter(collection, pattern, { keys }));
    }
  };

  return { filteredCollection, handleSearchPatternChange };
};
