import { ExclamationCircleIcon, InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";

import { Heading, Paragraph, RegularButton } from "@web/ui";
import { formatDate } from "@web/utils";

type Props = {
  isReconnecting: boolean;
  onClose: () => void;
  onContinueOffline: () => void;
  onSwitchToOnline: () => void;
  isManualNetworkStateEnabled: boolean;
  // TODO #11962: Remove `| undefined` when removing the `select-supplier` feature flag
  catalogLastUpdated: string | undefined;
};

export const OldCatalogDataModal = ({
  isReconnecting,
  onClose,
  onContinueOffline,
  onSwitchToOnline,
  isManualNetworkStateEnabled,
  catalogLastUpdated,
}: Props) => {
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const Icon = isManualNetworkStateEnabled ? ExclamationCircleIcon : InformationCircleIcon;
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="flex items-center justify-between gap-2">
        <div className="flex-none flex items-center justify-center p-1 rounded-full border bg-primaryBackground">
          <Icon className="w-5 text-primaryDefault" aria-hidden="true" />
        </div>
        <Heading size="200" className="flex-1">
          {hasSelectSupplierFeature
            ? "No internet connection detected"
            : "You need to be online to update the catalog"}
        </Heading>
        <button
          type="button"
          className="flex-none self-start bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-5">
        {hasSelectSupplierFeature ? (
          <div className="flex flex-col gap-4">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              You are offline, so we cannot provide you with the latest catalog of products.
            </Paragraph>
            {/* TODO #11962: Remove conditional rendering when `catalogLastUpdated` becomes mandatory */}
            {!!catalogLastUpdated && (
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                The chosen catalog was last updated on your device on{" "}
                {formatDate(catalogLastUpdated)}.
              </Paragraph>
            )}
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Since then, some products may have become unavailable or their prices may have
              changed. This will be verified when you place your order.
            </Paragraph>
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              We recommend updating the catalog when you have an internet connection to see the
              latest prices and item availability.
            </Paragraph>
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              By clicking the &quot;Understood&quot; button, you will start browsing the non-updated
              catalog.
            </Paragraph>
          </div>
        ) : (
          <>
            {isManualNetworkStateEnabled ? (
              <Paragraph size="100" color="text-textIcon-blackSecondary">
                The data has not been updated for more than 5 days for the chosen port. We recommend
                switching to online mode to update the catalog to get the latest item availability
                and prices.
              </Paragraph>
            ) : (
              <>
                <Paragraph size="100" color="text-textIcon-blackSecondary">
                  The catalog has not been updated for more than 5 days for the chosen port. We
                  recommend updating the catalog when you have an internet connection to see the
                  latest prices and item availability.
                </Paragraph>
                <Paragraph size="100" color="text-textIcon-blackSecondary" className="mt-2">
                  By clicking the &quot;Understood&quot; button, you will start browsing the
                  non-updated catalog.
                </Paragraph>
              </>
            )}
          </>
        )}
      </div>
      <div className="mt-5 flex flex-row-reverse">
        {/**
         * Additional condition using `hasSelectSupplierFeature` below exists
         * to ensure that Select Supplier feature and manual network toggle
         * do not mix together, since this is not supported, and the manual
         * network toggle is being sunset anyway
         */}
        <RegularButton
          variant="primary"
          size="large"
          className="ml-2"
          label={
            !hasSelectSupplierFeature && isManualNetworkStateEnabled
              ? "Switch To Online"
              : "Understood"
          }
          loading={
            !hasSelectSupplierFeature && isManualNetworkStateEnabled ? isReconnecting : false
          }
          onClick={
            !hasSelectSupplierFeature && isManualNetworkStateEnabled
              ? onSwitchToOnline
              : onContinueOffline
          }
        />
        {!hasSelectSupplierFeature && isManualNetworkStateEnabled && (
          <RegularButton
            variant="secondary"
            size="large"
            label="Continue Offline"
            onClick={onContinueOffline}
          />
        )}
      </div>
    </div>
  );
};
