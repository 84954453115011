import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useMemo } from "react";

import { LiteOrderTypeConfiguration, LiteProductSku, ProductsService } from "../../typegens";

export const AVAILABLE_ITEMS_QUERY_KEY_BASE = "availableItems";

type UseAvailableItemsQuery = {
  query: UseQueryResult<LiteProductSku[]>;
  invalidate: () => void;
};

export const useAvailableItemsQuery = ({
  variantIds,
  portId,
  orderType,
  supplierId,
  disabled,
}: {
  variantIds: string[];
  portId: string;
  orderType: LiteOrderTypeConfiguration["type"];
  supplierId: string;
  disabled: boolean;
}): UseAvailableItemsQuery => {
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () =>
      hasSelectSupplierFeature
        ? [AVAILABLE_ITEMS_QUERY_KEY_BASE, orderType, portId, supplierId, variantIds]
        : [AVAILABLE_ITEMS_QUERY_KEY_BASE, portId, variantIds, orderType],
    [hasSelectSupplierFeature, orderType, portId, supplierId, variantIds]
  );

  const query = useQuery<LiteProductSku[]>({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await ProductsService.getProductVariants(
        {
          requestBody: {
            portId,
            variantIds,
            orderType,
            supplierId: hasSelectSupplierFeature ? supplierId : undefined,
          },
        },
        { signal }
      );

      return response.items;
    },
    enabled:
      !!portId && !!orderType && (hasSelectSupplierFeature ? !!supplierId : true) && !disabled,
    gcTime: 0,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    query,
    invalidate,
  };
};
