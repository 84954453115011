import { DefaultError, useMutation } from "@tanstack/react-query";

import { useToastMessage } from "src/hooks/useToastMessage";
import { LocalStocktakeReportDraft, LocalStocktakeReportForm } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { StocktakeDraftsService } from "src/services/StocktakeDraftsService";

import { useStocktakeDraftQueryHelpers } from "./useStocktakeDraftQuery";
import { useStocktakeDraftsListQueryHelpers } from "./useStocktakeDraftsListQuery";
import { useStocktakesListQueryHelpers } from "./useStocktakesListQuery";

type UseUpdateStocktakeDraftMutationArgs = {
  onSuccess?: (updatedDraft: LocalStocktakeReportDraft) => void;
  onError?: (draftToUpdateId: string, error: Error) => void;
  hasErrorMessage?: boolean;
  vesselId: string;
  draftId: string;
};

export const useUpdateStocktakeDraftMutation = ({
  onSuccess,
  onError,
  hasErrorMessage = false,
  vesselId,
  draftId,
}: UseUpdateStocktakeDraftMutationArgs) => {
  const { setToastMessage } = useToastMessage();
  const { updateItemInQuery } = useStocktakesListQueryHelpers({ vesselId });
  const { updateItemInQuery: updateItemInDraftsQuery } = useStocktakeDraftsListQueryHelpers({
    vesselId,
  });
  const { invalidate: invalidateDraftQuery } = useStocktakeDraftQueryHelpers({ draftId });

  return useMutation<LocalStocktakeReportDraft, DefaultError, LocalStocktakeReportForm>({
    mutationKey: ["updateStocktakeDraft"],
    mutationFn: (stocktakeReportForm) => {
      const partialStocktakeDraft =
        LocalStocktakeService.convertFromReportFormToPartialDraft(stocktakeReportForm);
      return StocktakeDraftsService.updateDraft({ draftId, draftData: partialStocktakeDraft });
    },
    networkMode: "always",
    onSuccess: (updatedDraft) => {
      if (onSuccess) {
        onSuccess(updatedDraft);
      }

      const stocktakeReportListItem =
        LocalStocktakeService.convertFromStoredDraftToUiStocktakeListItem(updatedDraft);

      // Update the item in query caches
      updateItemInQuery(stocktakeReportListItem);
      updateItemInDraftsQuery(updatedDraft);

      // Invalidate the draft query to refetch the updated draft
      invalidateDraftQuery();
    },
    onError: (error) => {
      console.error(error, draftId);

      hasErrorMessage &&
        setToastMessage({
          type: "failure",
          message:
            "There was an error while saving your draft. If this happens multiple times, please contact Customer Support.",
        });

      if (onError) {
        onError(draftId, error);
      }
    },
  });
};
