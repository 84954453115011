import { useEffect, useRef } from "react";
import { ZodType } from "zod";

export const usePersistedState = <StoredData>(
  localStorageKey: string,
  state: StoredData,
  callback: (data: StoredData) => void,
  schema: ZodType
) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current === true) {
      try {
        const data = localStorage.getItem(localStorageKey);
        const value = {
          ...JSON.parse(data as string),
        };
        if (data) {
          const parsingResult = schema.parse(value) as StoredData;
          callback(parsingResult);
        }
      } catch (error) {
        console.log(error);
        localStorage.removeItem(localStorageKey);
      } finally {
        firstUpdate.current = false;
      }
      return;
    }
  }, [callback, localStorageKey, schema]);

  useEffect(() => {
    if (firstUpdate.current === false) {
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    }
  }, [localStorageKey, state]);
};
