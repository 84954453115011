import { useFlag } from "@unleash/proxy-client-react";

import { getCategory } from "@web/common/utils/categories";

import { useAppStateContext } from "../contexts/AppStateContext";
import useCategoriesQuery from "./useCategoriesQuery";
import { usePunchOutIntegration } from "./usePunchOutIntegration";

const useCategories = ({
  disabled = false,
}: {
  disabled?: boolean;
} = {}) => {
  const [{ port, orderType, supplier }] = useAppStateContext();
  const { supplierId: punchoutSupplierId } = usePunchOutIntegration();

  const hasSelectSupplierFeature = useFlag("select-supplier");
  const supplierId = hasSelectSupplierFeature ? supplier?.id : punchoutSupplierId;

  // Defaulting undefined values to empty strings is to make the compiler happy.
  // Empty string will never be passed in a request because of the `enabled` prop config.
  const { query, invalidate } = useCategoriesQuery(
    {
      portId: port?.id || "",
      // Supplier ID is allowed to be empty unless `select-supplier` flag is enabled
      supplierId: supplierId || "",
      orderType: orderType,
    },
    {
      enabled:
        !!port?.id && !!orderType && (hasSelectSupplierFeature ? !!supplierId : true) && !disabled,
    }
  );

  return {
    categoriesQuery: query,
    invalidateQuery: invalidate,
    getCategory: (categoryId: string) => getCategory(categoryId, query.data || []).reverse()[0],
    getBreadcrumb: (categoryId: string) => getCategory(categoryId, query.data || []),
  };
};

export default useCategories;
