import { Dispatch } from "react";
import { z } from "zod";

import { BasketEntry as CommonBasketEntry } from "@web/common";
import { assertTypeEquality } from "@web/utils";

import {
  LiteBasketFormAnswerSchema,
  LiteOrderExtraItemSchema,
  LiteProductSkuSchema,
  LiteRfqItemSchema,
  LocalLiteOrderExtraItem,
} from "src/models";
import { LiteBasketFormAnswer, LiteRfqItem } from "src/typegens";

export type SetBasketState = {
  type: "setBasketState";
  value: BasketState;
};

export type AddMultipleCatalogItemsToBasketAction = {
  type: "addMultipleCatalogItemsToBasket";
  value: Array<BasketEntry>;
};
export type SetCatalogItemInBasketAction = {
  type: "setCatalogItemInBasket";
  value: BasketEntry;
};

export type RemoveCatalogItemFromBasketAction = {
  type: "removeCatalogItemFromBasket";
  value: string;
};

export type RemoveMultipleEntriesFromBasketAction = {
  type: "removeMultipleEntriesFromBasket";
  value: Set<string>;
};

export type AddRfqToBasketAction = {
  type: "addRfqToBasket";
  value: LiteRfqItem;
};

export type RemoveRfqFromBasketAction = {
  type: "removeRfqFromBasket";
  value: string;
};

export type UpdateRfqInBasketAction = {
  type: "updateRfqInBasket";
  value: LiteRfqItem;
};

export type ClearBasketAction = {
  type: "clearBasket";
};

export type SetDraftAction = {
  type: "setDraft";
  value: { id: string; updatedAt: string };
};

// TODO #5641: Remove this action and fall back to `setDraft` everywhere when removing Online Draft logic
export type SetNewDraftAction = {
  type: "setNewDraft";
  value: { id: string; updatedAt: string; status?: DraftStatus };
};

export type ClearDraftAction = {
  type: "clearDraft";
};

export type AddMultipleRfqsToBasketAction = {
  type: "addMultipleRfqsToBasket";
  value: LiteRfqItem[];
};

export type SetBasketAnswersFormAction = {
  type: "setBasketAnswersForm";
  value: Array<LiteBasketFormAnswer>;
};

export type SetBasketExtraItemsAction = {
  type: "setBasketExtraItems";
  value: Array<LocalLiteOrderExtraItem>;
};

export type BasketStateAction =
  | AddMultipleRfqsToBasketAction
  | AddMultipleCatalogItemsToBasketAction
  | AddRfqToBasketAction
  | ClearBasketAction
  | SetCatalogItemInBasketAction
  | RemoveRfqFromBasketAction
  | RemoveCatalogItemFromBasketAction
  | RemoveMultipleEntriesFromBasketAction
  | SetBasketAnswersFormAction
  | SetBasketState
  | SetDraftAction
  | ClearDraftAction
  | SetNewDraftAction
  | UpdateRfqInBasketAction
  | SetBasketExtraItemsAction;

export type BasketDispatch = Dispatch<BasketStateAction>;

// TODO #5641: Remove DraftStatus along with Online Draft logic
// Related to #8117: Dirty fix to prevent overwriting the draft after it was cleared in some edge cases.
// Dirty fix because online draft is a legacy feature and Offline Draft is not susceptible to this problem.
export const DraftStatuses = ["CREATION_STARTED"] as const;
export type DraftStatus = typeof DraftStatuses[number];

const BasketEntrySchema = z.object({
  sku: LiteProductSkuSchema,
  quantity: z.number(),
});

export type BasketEntry = z.infer<typeof BasketEntrySchema>;

// Until the gather experience & common components are removed from @common, assert these types equality
assertTypeEquality<BasketEntry, CommonBasketEntry, BasketEntry>();

export const BasketStateSchema = z.object({
  basketAnswersForm: z.array(LiteBasketFormAnswerSchema).optional(),
  draft: z
    .object({ id: z.string(), updatedAt: z.string(), status: z.enum(DraftStatuses).optional() })
    .optional(),
  lastUpdated: z.number().optional(),
  lineItems: z.array(BasketEntrySchema),
  rfqItems: z.record(z.string(), LiteRfqItemSchema),
  extraItems: z.array(LiteOrderExtraItemSchema),
});

export type BasketState = z.infer<typeof BasketStateSchema>;

export const BasketStateStorageSchema = BasketStateSchema.omit({ basketAnswersForm: true }).extend({
  // Needs to be optional, because this field was added to the basket state after it was live in production
  extraItems: z.array(LiteOrderExtraItemSchema).optional(),
  /**
   *   TODO #11890: Ensure proper `BasketEntrySchema` schema for `lineItem` once the app
   *   starts parsing catalog data on all entries.
   *
   *   This is the only way we can proceed at the moment. We do not parse incoming catalog
   *   data on its way to the basket state, so we cannot guarantee what is stored in LS.
   *   Simply changing z.unknown() to BasketEntrySchema will cause the basket state loaded
   *   from LS to be parsed with error, so the basket state will never be set from LS upon
   *   page load.
   */
  lineItems: z.array(z.unknown()),
});

export type BasketStateStorage = z.infer<typeof BasketStateStorageSchema>;
