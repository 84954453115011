/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { LiteOfflineCatalog } from "../models/LiteOfflineCatalog";

export class LiteOfflineCatalogControllerService {
  /**
   * @returns LiteOfflineCatalog OK
   * @throws ApiError
   */
  public static getOfflineCatalog(
    {
      portId,
      lastUpdatedAt,
      orderType,
      supplierId,
    }: {
      portId: string;
      lastUpdatedAt?: string;
      orderType?: "BONDED" | "PROVISIONS" | "STORES" | "WELFARE" | "DEFAULT";
      supplierId?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteOfflineCatalog> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/offline/catalog",
      query: {
        portId: portId,
        lastUpdatedAt: lastUpdatedAt,
        orderType: orderType,
        supplierId: supplierId,
      },
      errors: {
        304: `Returned when catalog has no changes from lastUpdateAt date`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
