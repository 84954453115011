import { Attachment } from "@web/common/models/Attachment";

export const attachmentTypeToAttachmentNameMap: ReadonlyMap<Attachment["type"], string> = new Map([
  ["TYPE_APPROVAL", "Type approval"],
  ["DECLARATION_OF_CONFORMITY", "Declaration of conformity"],
  ["DNV", "Det Norske Veritas"],
  ["MARINE_EQUIPMENT_DIRECTIVE", "Marine Equipment Directive"],
  ["MATERIAL_SAFETY_DATA_SHEET", "Material Safety Data Sheet"],
  ["SAFETY_DATA_SHEETS", "Safety Data Sheets"],
  ["INVENTORY_OF_HAZARDOUS_MATERIALS", "Inventory of Hazardous Materials"],
  ["TEST_CERTIFICATE", "Test Certificate"],
  ["CERTIFICATE_OF_COMPLIANCE", "Certificate of Compliance"],
  ["MANUALS", "Manuals"],
  ["USER_INSTRUCTIONS", "User Instructions"],
  ["APPROVAL", "Approval"],
  ["DATA_SHEET", "Data Sheet"],
  ["DRAWING", "Drawing"],
  ["CERTIFICATE", "Certificate"],
  ["INSTRUCTIONS", "Instructions"],
  ["NOT_DEFINED", "Unspecified"],
]);
