import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { matchSorter } from "match-sorter";

import { useSearchInput } from "@web/common";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { LocalLiteProduct } from "src/models";
import { s2sDatabase } from "src/objectStorage";

import { getCompositeStoredCatalogCategoryId } from "../utils";

// TODO #10712: This is a PoC. May contain dead code, bugs and performance issues.

export const CACHED_PRODUCTS_QUERY_KEY_BASE = "cachedProducts";
type UseCachedProductsQuery = UseQueryResult<LocalLiteProduct[]>;

export const useCachedProductsQuery = ({
  categoryIds,
  enabled,
}: {
  categoryIds: string[];
  enabled: boolean;
}): UseCachedProductsQuery => {
  const hasSelectSupplierFeature = useFlag("select-supplier");
  const [{ port, orderType, supplier }] = useAppStateContext();
  return useQuery<LocalLiteProduct[]>({
    queryKey: [CACHED_PRODUCTS_QUERY_KEY_BASE, port?.id],
    queryFn: async () => {
      if (!port || !orderType || (hasSelectSupplierFeature ? !supplier : false)) {
        return [];
      }
      const products: LocalLiteProduct[] = [];
      for (const categoryId of categoryIds) {
        const categories = await s2sDatabase.categories.get({
          categoryPortId: getCompositeStoredCatalogCategoryId({
            orderType,
            portId: port.id,
            // TODO #11962: Remove optional chain and default value when removing `select-supplier` flag
            supplierId: supplier?.id || "",
            categoryId,
          }),
        });
        if (categories) {
          products.push(...categories.products);
        }
      }
      const ids = products.map(({ id }) => id);

      // Return deduplicated products
      return products.filter(({ id }, index) => !ids.includes(id, index + 1));
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
    networkMode: "always",
    enabled: !!port && !!orderType && (hasSelectSupplierFeature ? !!supplier : true) && enabled,
  });
};

export const OFFLINE_PRODUCTS_SEARCH_QUERY_KEY_BASE = "offlineProductsSearch";
type UseOfflineProductsSearchQuery = UseQueryResult<LocalLiteProduct[]>;

export const useOfflineProductsSearchQuery = ({
  products,
  enabled,
}: {
  products: LocalLiteProduct[];
  enabled: boolean;
}): UseOfflineProductsSearchQuery => {
  const { query } = useSearchInput();

  return useQuery<LocalLiteProduct[]>({
    queryKey: [OFFLINE_PRODUCTS_SEARCH_QUERY_KEY_BASE, products, query],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        try {
          const results = matchSorter(products, query, {
            keys: ["name", "description"],
          }).slice(0, 200);
          resolve(results);
        } catch (error) {
          reject(error);
        }
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    networkMode: "always",
    enabled: !!query && enabled,
  });
};
