// TODO #11548: Add unit tests

// Based on the following package: https://github.com/Polarisation/indexeddb-export-import/tree/master
/**
 * @example
 * const connection = indexedDB.open('db-name');
 * connection.onsuccess = (event) => {
 *   const db = (event.target as IDBOpenDBRequest).result;
 *   exportIdbData(db).then(console.log).catch(console.error);
 * }
 */
export const exportIdbData = (idbDatabase: IDBDatabase): Promise<{ [key: string]: unknown }> => {
  return new Promise((resolve, reject) => {
    const exportObject: { [key: string]: unknown } = {};
    const objectStoreNamesSet = new Set(idbDatabase.objectStoreNames);
    const size = objectStoreNamesSet.size;

    if (size === 0) {
      resolve(exportObject);
    } else {
      const objectStoreNames = Array.from(objectStoreNamesSet);
      const transaction = idbDatabase.transaction(objectStoreNames, "readonly");

      transaction.onerror = (event) => reject(event);

      objectStoreNames.forEach((storeName) => {
        const allObjects: unknown[] = [];
        transaction.objectStore(storeName).openCursor().onsuccess = (event) => {
          const cursor = (event?.target as IDBOpenDBRequest | undefined)?.result as
            | IDBCursorWithValue
            | undefined;
          if (cursor) {
            // Cursor holds value, put it into store data
            allObjects.push(cursor.value);
            cursor.continue();
          } else {
            // No more values, store is done
            exportObject[storeName] = allObjects;

            // Last store was handled
            if (size === Object.keys(exportObject).length) {
              resolve(exportObject);
            }
          }
        };
      });
    }
  });
};
