import * as Sentry from "@sentry/browser";
import { useFlag } from "@unleash/proxy-client-react";
import { Dispatch, PropsWithChildren, createContext, useContext, useEffect, useRef } from "react";

import { BASKET_STORAGE_KEY, VESSEL_STORAGE_KEY } from "src/config/constants";

import useAppState from "../hooks/useAppState";
import { defaultAppState } from "../state/AppState";
import AppState, { AppStateAction } from "../state/models";
import { LiteSupplierInformation } from "../typegens";
import { BasketEntry, BasketStateStorageSchema } from "./BasketContext";

export const AppStateContext = createContext<[state: AppState, action: Dispatch<AppStateAction>]>([
  defaultAppState,
  () => {
    return;
  },
]);

export const useAppStateContext = () => useContext(AppStateContext);

export const AppStateLifecycle: React.FC = () => {
  const [appState, dispatch] = useAppStateContext();
  const firstUpdate = useRef<boolean>(true);
  const hasSelectSupplierFeature = useFlag("select-supplier");

  useEffect(() => {
    if (firstUpdate.current === true) {
      const data = localStorage.getItem(VESSEL_STORAGE_KEY);
      // we remove vesselToken property before dispatching action
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { vesselToken = null, ...value } = { ...JSON.parse(data as string) };
      if (data) {
        dispatch({
          type: "setAppState",
          value,
        });
      }
      firstUpdate.current = false;
      return;
    }
    // NOTE: For now not full state is stored
    // previously this logic was invalid and updating full store should be better tested
    const { orderType, port, deliveryDate, dutyFreeDeclaration, appArea, orderName, supplier } =
      appState;

    // TODO #11983: Remove some months after select supplier feature is released
    // This tries to hydrate supplier data in state for a vessel that is in the middle
    // of a gather experience when the feature gets released.
    // Provided that the product IDs in the catalog pointed by the combination of
    // orderType, port & supplier are the same as the ones in the catalog browsed
    // when the feature was released, user will be able to continue shopping
    // both online & offline.
    let calculatedSupplier: LiteSupplierInformation | undefined = supplier;
    if (hasSelectSupplierFeature && (!supplier || !supplier.id)) {
      try {
        // Try to get the current basket from LS - basket state is not available at this level
        const storedBasketStateString = localStorage.getItem(BASKET_STORAGE_KEY);
        const parsedStoredBasket = {
          ...JSON.parse(storedBasketStateString as string),
        };
        if (storedBasketStateString) {
          const basketState = BasketStateStorageSchema.parse(parsedStoredBasket);
          const firstLineItem: BasketEntry | undefined = (
            basketState.lineItems as unknown as BasketEntry[]
          )[0];
          const supplierId: string =
            firstLineItem.sku?.supplierInformation.supplierId ||
            firstLineItem.sku?.supplier?.supplierId ||
            "";
          const supplierName: string = firstLineItem.sku?.supplierInformation.name || "";

          if (supplierId) {
            calculatedSupplier = {
              id: supplierId,
              name: supplierName,
            };
          }
        }
      } catch (error) {
        // Only report an error, but do not throw
        Sentry.captureException(error);
      }
    }

    localStorage.setItem(
      VESSEL_STORAGE_KEY,
      JSON.stringify({
        orderType,
        deliveryDate,
        dutyFreeDeclaration,
        port,
        appArea,
        orderName,
        supplier: calculatedSupplier,
      })
    );
  }, [appState, dispatch, hasSelectSupplierFeature]);

  return null;
};

export const AppStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [appState, dispatch] = useAppState();

  return (
    <AppStateContext.Provider value={[appState, dispatch]}>{children}</AppStateContext.Provider>
  );
};

export default AppStateContext;
