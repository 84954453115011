import classnames from "classnames";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactReusableAutocomplete as Autocomplete } from "react-reusable-autocomplete";

import { Port } from "@web/models";
import {
  CircledImage,
  DatePicker,
  Heading,
  Input,
  Label,
  OptionType,
  Paragraph,
  RegularButton,
  Select,
  TimePicker,
} from "@web/ui";
import { convertTimeToISODateString, formatDate } from "@web/utils";

import { DutyFreeDeclaration } from "../../components";
import AnchorWhite from "../../icons/AnchorWhite.svg";
import { OrderType, OrderTypeConfig } from "../../models";
import {
  LEGACY_getSelectOptionFromOrderTypeConfiguration,
  getDefaultOrderName,
  shouldRenderOrderTypes,
} from "../../utils";

type Props = {
  onSubmitForm: (form: Form) => void;
  ports: Port[];
  preselectedOrderType?: OrderType;
  preselectedPort?: Port;
  isPunchOutIntegration?: boolean;
  orderTypes?: OrderTypeConfig[];
  renderAttentionInfo?: ({ selectedPort }: { selectedPort?: Port }) => React.ReactElement | null;
  isOrderNameEnabled?: boolean;
};

type PortProps = {
  port: Port;
  className?: string;
};

export type Form = {
  port?: Port;
  deliveryDate?: string;
  dutyFreeDeclaration?: {
    dutyFree: boolean;
    name: string;
    position: string;
  };
  orderType?: OrderType;
  orderName?: string;
};

export type FormValues = {
  port?: Port;
  deliveryDate?: {
    date: string;
    time: string;
  };
  dutyFreeDeclaration: {
    dutyFree: "yes" | "no";
    name: string;
    position: string;
  };
  orderType?: OrderType;
  orderName?: string;
};

const Divisor = () => <hr className="my-1" />;

export const PreconfigureOrderSetup: React.FC<Props> = ({
  ports,
  onSubmitForm,
  preselectedOrderType,
  preselectedPort,
  isPunchOutIntegration,
  orderTypes,
  renderAttentionInfo,
  isOrderNameEnabled,
}) => {
  const OptionTypeSelect = Select<OrderType | undefined>;
  const canRenderOrderTypes = shouldRenderOrderTypes({
    orderTypes,
    isPunchOutIntegration: !!isPunchOutIntegration,
  });
  const form = useForm<FormValues>({
    defaultValues: {
      port: preselectedPort,
      deliveryDate: {
        date: "",
        time: "09:00",
      },
      dutyFreeDeclaration: {
        dutyFree: undefined,
        name: "",
        position: "",
      },
      orderType: preselectedOrderType,
      orderName: "",
    },
    mode: "onBlur",
    shouldUnregister: true,
  });

  const { register, control, handleSubmit, formState, getValues, setValue, watch } = form;
  const { errors } = formState;
  const selectedPort = watch("port");
  const { t } = useTranslation();
  const isDutyFreeEnabled = watch("port")?.dutyFree;
  const isDutyFree = watch("dutyFreeDeclaration.dutyFree");

  const orderTypeOptions: OptionType<OrderType>[] = useMemo(
    () =>
      (orderTypes || []).map((orderType) =>
        LEGACY_getSelectOptionFromOrderTypeConfiguration(orderType)
      ),
    [orderTypes]
  );

  const getPort = (portId?: string) => {
    if (!portId) {
      return undefined;
    }

    return ports.find((port) => port.id === portId);
  };

  const onSubmit = () => {
    const { dutyFreeDeclaration, deliveryDate, orderType, ...values } = getValues();
    const result = {
      ...values,
      ...(!isPunchOutIntegration
        ? {
            deliveryDate: convertTimeToISODateString(deliveryDate?.time, deliveryDate?.date),
          }
        : {}),
      ...(isDutyFreeEnabled
        ? {
            dutyFreeDeclaration: {
              dutyFree: dutyFreeDeclaration.dutyFree === "yes",
              name: dutyFreeDeclaration.name,
              position: dutyFreeDeclaration.position,
            },
          }
        : {}),
      orderType,
      orderName:
        isOrderNameEnabled && !isPunchOutIntegration
          ? values.orderName || getDefaultOrderName(getPort(values.port?.id), deliveryDate?.date)
          : undefined,
    };

    onSubmitForm(result);
  };

  const Result: React.FC<PortProps> = ({ port, className }) => {
    return (
      <div className={classnames("flex items-center justify-between bg-white-500", className)}>
        <div className="flex items-center grow w-[70%]">
          <CircledImage className="inline" Icon={AnchorWhite} size={5} hashKey={port.id} />
          <div>
            <div className="flex">
              <Paragraph className="mr-2" size="200" weight="heavy">
                {port.locationCode}
              </Paragraph>
              <Paragraph color="text-textIcon-blackSecondary" size="200" weight="heavy">
                {port.name}
              </Paragraph>
            </div>
            {port.offlineCatalogUpdatedAt && (
              <Paragraph size="400">
                Offline catalog last updated {formatDate(port.offlineCatalogUpdatedAt)}
              </Paragraph>
            )}
          </div>
        </div>
        <div className="mr-[13px] w-[30%]">
          <Label size="400">{!port.freeDelivery && "Delivery charges may apply"}</Label>
        </div>
      </div>
    );
  };

  return (
    <>
      <form
        className="flex flex-col gap-6 flex-grow mt-10 min-w-min mx-auto"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isOrderNameEnabled && !isPunchOutIntegration && (
          <>
            <Heading size="200">Order Name</Heading>
            <Controller
              name="orderName"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="Order name" maxLength={128} withBorder />
              )}
            />
            <Divisor />
          </>
        )}
        {canRenderOrderTypes && (
          <>
            <Heading size="200">Order Type</Heading>
            <Controller
              name="orderType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select an order type",
                },
              }}
              render={({ field }) => (
                <OptionTypeSelect
                  {...field}
                  onChange={(option) => field.onChange(option.value)}
                  value={orderTypeOptions.find((option) => option.value === field.value)}
                  options={orderTypeOptions}
                  placeholder="Select order type..."
                  className="w-[250px]"
                  errorMessage={errors.orderType?.message}
                />
              )}
            />
            <Divisor />
          </>
        )}
        <div className="flex flex-col gap-4">
          <Heading size="200">{t("common.views.portSelector.title")}</Heading>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {t("common.views.portSelector.copy")}
          </Paragraph>
          <div className="flex flex-col gap-2">
            <Controller
              name="port"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select a port",
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  className="bg-red-400"
                  data-testid="portSelector_port"
                  items={ports}
                  onSelect={(port: Port) => {
                    setValue(field.name, port, { shouldDirty: true, shouldTouch: true });
                  }}
                  inputSearchString={field.value?.name}
                  showClear={false}
                  showIcon={false}
                  styling={{
                    fontFamily:
                      '"Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                    fontWeight: "100",
                    backgroundColor: "white",
                    border: "1px solid #b8bbc3",
                    borderRadius: "6px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                    hoverBackgroundColor: "#f3f4f6",
                    fontSize: "14px",
                    height: "40px",
                    padding: "0",
                    lineHeight: "150px",
                    margin: "0",
                    maxResults: "200",
                    width: "100%",
                    zIndex: 100,
                  }}
                  fuseOptions={{
                    shouldSort: true,
                    keys: ["_search"],
                    isCaseSensitive: false,
                    includeScore: true,
                  }}
                  formatResult={(port: Port) => <Result port={port} />}
                  resultStringKeyName={"name"}
                  placeholder={"Which port will you arrive at?"}
                  error={
                    errors?.port?.message && (
                      <Paragraph size="200" color="text-dangerDefault">
                        {errors?.port?.message}
                      </Paragraph>
                    )
                  }
                />
              )}
            />
          </div>
        </div>
        {renderAttentionInfo &&
          renderAttentionInfo({
            selectedPort,
          })}
        {!isPunchOutIntegration && (
          <>
            <Divisor />
            {/* TODO: #11699 - refactor DeliveryDate and use it here */}
            <div className="flex flex-col gap-4">
              <Heading size="200">Desired delivery date</Heading>
              <div className="flex gap-4">
                <Controller
                  name="deliveryDate.date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("components.contactInfo.dateEmpty"),
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        label={t("components.contactInfo.etaDate")}
                        additionalProps={{}}
                        onChange={field.onChange}
                        dataTestId="deliveryDate_datePicker"
                        error={
                          errors?.deliveryDate?.date?.message && (
                            <Paragraph size="200" color="text-dangerDefault">
                              {errors?.deliveryDate?.date?.message}
                            </Paragraph>
                          )
                        }
                        placeholder={t("components.contactInfo.etaDatePlaceholder")}
                      />
                    );
                  }}
                />
                <Controller
                  name="deliveryDate.time"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^(0?[0-9]|1[0-9]|(2[0-3])):[0-5][0-9]$/gm,
                      message: t("components.contactInfo.timeInvalid"),
                    },
                    required: {
                      value: true,
                      message: t("components.contactInfo.timeEmpty"),
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TimePicker
                        label={t("components.contactInfo.etaTime")}
                        additionalProps={{}}
                        onChange={field.onChange}
                        initialValue="09:00"
                        dataTestId="deliveryDate_timePicker"
                        description={t("components.contactInfo.timePort")}
                        placeholder={t("components.contactInfo.etaTimePlaceholder")}
                        error={
                          errors?.deliveryDate?.time?.message && (
                            <Paragraph size="200" color="text-dangerDefault">
                              {errors?.deliveryDate?.time?.message}
                            </Paragraph>
                          )
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>
          </>
        )}
        {isDutyFreeEnabled && (
          <>
            <Divisor />
            <DutyFreeDeclaration isDutyFree={isDutyFree} errors={errors} register={register} />
          </>
        )}
        <div className="flex justify-end mt-5">
          <RegularButton
            variant="primary"
            size="large"
            label="Start shopping"
            type="submit"
            data-testid="preconfigureOrderSetup_submit"
          />
        </div>
      </form>
    </>
  );
};
