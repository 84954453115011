import { CalendarIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { format, isPast, isToday, parseISO } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import { useState } from "react";
import { CalendarModifiers, DatePicker as NextDatePicker } from "react-next-dates";
import "react-next-dates/dist/style.css";

import { Label } from "../../atoms";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalProps?: Record<string, any>;
  dataTestId?: string;
  error?: string | React.ReactElement;
  initialValue?: string;
  label: string;
  name?: string;
  onChange?: (date: string) => void;
  placeholder?: string;
}

const modifiers: CalendarModifiers = {
  day: {
    disabled: (date: Date) => isPast(date) && !isToday(date),
  },
};

export const DatePicker: React.FC<Props> = (props) => {
  const {
    label,
    placeholder = "Select the date",
    dataTestId,
    additionalProps,
    initialValue = "",
    error,
    onChange,
  } = props;

  const [date, setDate] = useState<Date | null>(() => {
    if (initialValue !== "") {
      try {
        return parseISO(initialValue);
      } catch (e) {
        console.info("Error parsing date", initialValue);
      }
    }
    return null;
  });

  const handleChange = (selectedDate: Date | null) => {
    if (!selectedDate) {
      return;
    }
    setDate(selectedDate);
    onChange?.(format(selectedDate, "yyyy-MM-dd"));
  };

  return (
    <div className="flex flex-col gap-2">
      <Label size="200" color="text-textIcon-blackSecondary">
        {label}
      </Label>
      <NextDatePicker date={date} onChange={handleChange} locale={enGB} modifiers={modifiers}>
        {({ inputProps }) => {
          const mergeProps = { ...additionalProps, ...inputProps };

          if (date) {
            mergeProps.value = format(date, "yyyy-MM-dd");
          } else {
            mergeProps.placeholder = placeholder;
          }

          return (
            <div className="flex items-center relative cursor-pointer">
              <input
                {...mergeProps}
                readOnly
                data-testid={dataTestId}
                className={classnames(
                  "shadow-sm w-full border border-solid focus:border-solid rounded-md py-2 pl-3 focus:outline-none cursor-pointer text-sm leading-5 font-light h-6.5",
                  {
                    "border-dangerDefault": error,
                    "border-neutral_300": !error,
                  }
                )}
              />
              {error ? (
                <ExclamationCircleIcon
                  className="h-5 w-5 text-dangerDefault absolute right-2 cursor-pointer pointer-events-none"
                  aria-hidden="true"
                />
              ) : (
                <CalendarIcon className="h-4 w-4 text-textIcon-blackSecondary absolute right-2 cursor-pointer pointer-events-none" />
              )}
            </div>
          );
        }}
      </NextDatePicker>
      {error}
    </div>
  );
};
