import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSpinDelay } from "spin-delay";

import { IntersectionMonitor, Product } from "@web/common";
import { Loading } from "@web/ui";

import { OfflineCatalogLoader } from "src/components/OfflineCatalogLoader";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useBasket from "src/hooks/useBasket";
import { useOfflineCatalogQueryHelpers } from "src/hooks/useOfflineCatalogQuery";

import EmptyIndicator from "../../components/EmptyIndicator";
import { NoCachedData } from "../../components/NoCachedData";
import { ProductBox } from "../../components/ProductBox";
import { SidebarBasket } from "../../components/SidebarBasket";
import { useNoCachedQueryGuard } from "../../hooks/useNoCachedQueryGuard";
import useProducts from "../../hooks/useProducts";
import { LocalProductService } from "../../services/LocalProductService";
import { LiteProduct } from "../../typegens";

const ProductsList = () => {
  const { t } = useTranslation();
  const { categoryId } = useParams() as { categoryId: string };
  const { pushCatalogItemToBasket, getQuantity } = useBasket();
  const [{ port, orderType, supplier }] = useAppStateContext();

  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isDownloadingOfflineCatalog, hasOfflineCatalogData } = useOfflineCatalogQueryHelpers({
    portId: port?.id || "",
    orderType,
    categoryId,
    supplierId: supplier?.id,
  });
  const { query, invalidate } = useProducts(
    categoryId,
    isDownloadingOfflineCatalog || (areOfflineCapabilitiesEnabled && !hasOfflineCatalogData)
  );
  const shouldDisplayOfflineCatalogLoader = useSpinDelay(isDownloadingOfflineCatalog, {
    delay: 800,
    ssr: false,
  });

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryPending: query.isPending,
    isQueryError: query.isError,
    becameOnlineCallback: invalidate,
  });

  const handleAddToBasket = useCallback(
    (product: Product, qty: number) =>
      pushCatalogItemToBasket(LocalProductService.convertProductToBasketEntry(product, qty)),
    [pushCatalogItemToBasket]
  );

  if (hasNoCachedData) {
    return <NoCachedData hasGoBack={true} displayMode="inline" />;
  }

  if (shouldDisplayOfflineCatalogLoader) {
    return <OfflineCatalogLoader className="mt-[175px]" inline />;
  }

  if (query.isPending) {
    return <Loading />;
  }

  if (query.isError) {
    return <div>Fetching error</div>;
  }

  const items = query.data.pages.flatMap((p) => p.items);

  if (items.length === 0) {
    return (
      <div className="flex-grow flex items-center w-full">
        <EmptyIndicator
          title={t("pages.category.noResults")}
          subtitle={t("pages.category.tryDifferentFilters")}
        />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="grid grid-cols-1 gap-2 w-11/12 pr-8 pl-4">
          {items.map((product: LiteProduct, index: number) => (
            <ProductBox
              addToBasket={handleAddToBasket}
              key={`${product.id}-${index}`}
              product={product}
              quantity={getQuantity(product.skuList[0])}
              index={index}
            />
          ))}
        </div>
        <SidebarBasket />
      </div>
      {query.hasNextPage && (
        <IntersectionMonitor onEnter={query.fetchNextPage}>
          <div className="h-10">{query.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </>
  );
};

export default ProductsList;
