import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { ProductSku } from "@web/common";
import {
  getBasketGrandTotal,
  getLineItemEntityQuantity,
  getLineItemTotal,
  getMinimumQuantityNumber,
  getTotalQuantity,
} from "@web/common/utils";
import { Money } from "@web/models";

import { BASKET_ANSWERS_FORM_STORAGE_KEY } from "src/config/constants";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { BasketEntry, DraftStatus, useBasketState } from "src/contexts/BasketContext";
import { LocalBasketExtraItemForm, LocalLiteOrderExtraItem } from "src/models";
import { LiteBasketFormAnswer, LiteRfqItem } from "src/typegens";

const useBasket = () => {
  const {
    state: {
      basketAnswersForm,
      draft,
      lineItems,
      rfqItems: basketRfqItems,
      extraItems: basketExtraItems,
      lastUpdated,
    },
    dispatch: basketStateDispatch,
  } = useBasketState();

  const [{ configuration }, appStateDispatch] = useAppStateContext();
  const areOrderExtraItemsEnabled = !!configuration?.fleet.allow.orderExtraItems;
  const hasRfqFeature =
    configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
    configuration.fleet.allow.createRfq;

  const extraItems = areOrderExtraItemsEnabled ? basketExtraItems : [];

  const rfqItems = hasRfqFeature ? Object.values(basketRfqItems) : [];

  const totalQuantity = getTotalQuantity(lineItems, rfqItems, extraItems);

  const getLineItem = (sku: ProductSku) => {
    return lineItems.find((lineItem) => lineItem.sku.id === sku.id);
  };

  const getQuantity = (sku: ProductSku): number => {
    const lineItem = getLineItem(sku);
    return lineItem ? lineItem.quantity : 0;
  };

  const getLineItemTotalPrice = (sku: ProductSku): Money | null => {
    const lineItem = getLineItem(sku);
    return lineItem ? getLineItemTotal(lineItem) : null;
  };

  const addMultipleCatalogItemsToBasket = (list: Array<BasketEntry>) => {
    basketStateDispatch({
      type: "addMultipleCatalogItemsToBasket",
      value: list,
    });
  };

  const addMultipleRfqsToBasket = (list: LiteRfqItem[]) => {
    if (!hasRfqFeature) {
      return;
    }
    basketStateDispatch({
      type: "addMultipleRfqsToBasket",
      value: list,
    });
  };

  const addRfqToBasket = (rfq: LiteRfqItem) => {
    if (!hasRfqFeature) {
      return;
    }
    basketStateDispatch({
      type: "addRfqToBasket",
      value: rfq,
    });
  };

  const updateRfqInBasket = (rfq: LiteRfqItem) => {
    if (!hasRfqFeature) {
      return;
    }
    basketStateDispatch({
      type: "updateRfqInBasket",
      value: rfq,
    });
  };

  const removeRfqFromBasket = (id: string) => {
    basketStateDispatch({
      type: "removeRfqFromBasket",
      value: id,
    });
  };

  const removeMultipleEntriesFromBasket = (ids: Set<string>) => {
    basketStateDispatch({
      type: "removeMultipleEntriesFromBasket",
      value: ids,
    });
  };

  const setBasketExtraItems = useCallback(
    (list: Array<LocalLiteOrderExtraItem>) => {
      if (!areOrderExtraItemsEnabled) {
        return;
      }
      basketStateDispatch({
        type: "setBasketExtraItems",
        value: list,
      });
    },
    [areOrderExtraItemsEnabled, basketStateDispatch]
  );

  const clearBasket = () => {
    // We are storing basket (budget) form answers in a separate key that needs to be removed,
    // whenever we want to reset basket state
    localStorage.removeItem(BASKET_ANSWERS_FORM_STORAGE_KEY);

    basketStateDispatch({
      type: "clearBasket",
    });
  };

  const clearOrderRequisitionFlow = () => {
    clearBasket();
    appStateDispatch({ type: "clearOrderFlow" });
  };

  const pushCatalogItemToBasket = useCallback(
    (basketEntry: BasketEntry) => {
      if (basketEntry.quantity === 0) {
        basketStateDispatch({
          type: "removeCatalogItemFromBasket",
          value: basketEntry.sku.id,
        });
      } else {
        basketStateDispatch({
          type: "setCatalogItemInBasket",
          value: basketEntry,
        });
      }
    },
    [basketStateDispatch]
  );

  const canDecrease = (sku: ProductSku) => getQuantity(sku) > getMinimumQuantityNumber(sku);

  const getOrderItems = () =>
    lineItems.map((lineItem) => ({
      variantId: getVariantId(lineItem.sku),
      entityQuantity: getLineItemEntityQuantity(lineItem),
    }));

  const getVariantId = (sku: ProductSku): string => sku.id;

  const setDraft = ({ draftId, updatedAt }: { draftId: string; updatedAt: string }) => {
    basketStateDispatch({
      type: "setDraft",
      value: { id: draftId, updatedAt },
    });
  };

  // TODO #5641: Remove this action and fall back to `setDraft` everywhere when removing Online Draft logic
  const setNewDraft = ({
    draftId,
    updatedAt,
    status,
  }: {
    draftId: string;
    updatedAt: string;
    status?: DraftStatus;
  }) => {
    basketStateDispatch({
      type: "setNewDraft",
      value: { id: draftId, updatedAt, status },
    });
  };

  const clearDraft = () => {
    basketStateDispatch({
      type: "clearDraft",
    });
  };

  const setBasketAnswersForm = (basketAnswersForm: LiteBasketFormAnswer[]) => {
    basketStateDispatch({
      type: "setBasketAnswersForm",
      value: basketAnswersForm,
    });
  };

  const grandTotal: Money = getBasketGrandTotal(lineItems);

  const getEmptyExtraItem = useCallback(
    (): Required<LocalBasketExtraItemForm> => ({
      id: uuidv4(),
      name: "",
      quantity: null,
      measurementUnit: "",
      refProductCode: "",
      refUrl: "",
    }),
    []
  );

  return {
    addMultipleRfqsToBasket,
    addMultipleCatalogItemsToBasket,
    addRfqToBasket,
    basketAnswersForm,
    canDecrease,
    clearBasket,
    clearOrderRequisitionFlow,
    draft,
    getLineItem,
    getLineItemTotalPrice,
    getOrderItems,
    getQuantity,
    getVariantId,
    grandTotal,
    lastUpdated,
    lineItems,
    rfqItems,
    extraItems,
    pushCatalogItemToBasket,
    removeRfqFromBasket,
    removeMultipleEntriesFromBasket,
    setBasketAnswersForm,
    setDraft,
    setNewDraft,
    clearDraft,
    totalQuantity,
    updateRfqInBasket,
    setBasketExtraItems,
    getEmptyExtraItem,
  };
};

export default useBasket;
