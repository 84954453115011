import { exportIdbData } from "./exportIdbData";
import { isDefined } from "./isDefined";

// TODO #11548: Add unit tests

/**
 * @example
 * connectAndExportIdbData('db-name');
 */
export const connectAndExportIdbData = (
  storeName: string,
  version?: number
): Promise<{ [key: string]: unknown }> => {
  return new Promise((resolve, reject) => {
    const connection = isDefined(version)
      ? indexedDB.open(storeName, version)
      : indexedDB.open(storeName);
    connection.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      exportIdbData(db)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    };
    connection.onerror = (event) => reject(event);
  });
};
