import { Dispatch, useReducer } from "react";

import { appStateReducer, defaultAppState } from "../state/AppState";
import AppState, { AppStateAction } from "../state/models";

const useAppState = (): [AppState, Dispatch<AppStateAction>] => {
  const [state, dispatch] = useReducer(appStateReducer, defaultAppState);
  return [state, dispatch];
};

export default useAppState;
