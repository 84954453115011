import { OrderRequisition } from "@web/common/network/model";

import { CheckItemsAvailabilityModal } from "src/components/Modal";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useAvailableItemsQuery } from "src/hooks/catalog/useAvailableItemsQuery";
import { useOfflineCatalogQuery } from "src/hooks/useOfflineCatalogQuery";

interface Props {
  draft: OrderRequisition;
}

export const CheckItemsAvailabilityModalController = ({ draft }: Props) => {
  const draftVariantIds = draft.items.map((item) => item.variantId);

  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const offlineCatalogQuery = useOfflineCatalogQuery({
    portId: draft.port.id,
    orderType: draft.orderType,
    supplierId: draft.supplierInformation.id,
  });

  const { query: availableItemsQuery } = useAvailableItemsQuery({
    variantIds: draftVariantIds,
    portId: draft.port.id,
    orderType: draft.orderType,
    supplierId: draft.supplierInformation.id,
    disabled: areOfflineCapabilitiesEnabled && offlineCatalogQuery.isFetching,
  });

  return (
    <CheckItemsAvailabilityModal
      draft={draft}
      availableItemsQuery={availableItemsQuery}
      isDownloadingOfflineCatalog={offlineCatalogQuery.isFetching}
    />
  );
};
