import { KeyboardEventHandler, useEffect, useState } from "react";

import { DebuggerMenu } from "./DebuggerMenu";

type Props = {
  errorDescription?: string;
  error?: unknown;
};

export const DebuggerMenuController = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const eventHandler: KeyboardEventHandler<HTMLBodyElement> = (e) => {
      if (e.ctrlKey && e.altKey && e.code === "KeyD") {
        e.preventDefault();
        setIsOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", eventHandler as unknown as EventListener);
    return () => document.removeEventListener("keydown", eventHandler as unknown as EventListener);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return isOpen ? <DebuggerMenu onClose={handleClose} {...props} /> : null;
};
