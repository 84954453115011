import { DefaultError, useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getDefaultOrderName } from "@web/common";

import { createRequisition } from "src/api/createRequisition";
import { AttentionInfo, orderExtraItemsTermsAttentionInfoItem } from "src/components/AttentionInfo";
import RequisitionInfo, {
  SubmitRequisitionFormData,
} from "src/components/RequisitionInfo/RequisitionInfo";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useDeleteOrderDraftMutation } from "src/hooks/orderDrafts/useDeleteOrderDraftMutation";
import useBasket from "src/hooks/useBasket";
import { useBasketVerification } from "src/hooks/useBasketVerification";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { useProductVariantsMutation } from "src/hooks/useProductVariantsMutation";
import { useToastMessage } from "src/hooks/useToastMessage";
import { RequisitionLayout } from "src/layouts";
import { ordersStore } from "src/objectStorage";
import { LiteCreateRequisitionRequest, LiteOrderRequisition } from "src/typegens";
import {
  deleteDraftFromOrderRequisitionsList,
  isApiError,
  prependToOrderRequisitionsList,
} from "src/utils";
import { useValidateCheckoutOrder } from "src/utils/useValidateCheckoutOrder";

export const RequisitionSummary = () => {
  const navigate = useNavigate();
  const { basketAnswersForm, draft, lineItems, extraItems, clearOrderRequisitionFlow } =
    useBasket();
  const [appState, dispatch] = useAppStateContext();
  const queryClient = useQueryClient();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDraftValid = searchParams.get("from") === "draft" && !!draft?.id;
  const { setToastMessage } = useToastMessage();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();

  const isCloseAttributeEnabled = useFlag("close-attribute");
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  const { mutate: deleteDraft } = useDeleteOrderDraftMutation({
    hasErrorMessage: true,
  });
  const { mutateAsync: productVariantsAsyncMutation, isPending: isProductVariantsMutationPending } =
    useProductVariantsMutation();
  const { findChangedProducts, BasketVerificationModal } = useBasketVerification();

  const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(
    undefined
  );

  const { validateOrderWithSideEffects } = useValidateCheckoutOrder();

  const handleDraftRemoval = async () => {
    if (!isDraftValid) {
      return;
    }

    if (isOfflineDraftEnabled) {
      return deleteDraft(draft.id);
    }

    queryClient.setQueryData(
      // Drafts are not closed, so this simplification is enough
      isCloseAttributeEnabled ? ["orderRequisitionsList", false] : ["orderRequisitionsList"],
      deleteDraftFromOrderRequisitionsList(draft.id)
    );
  };

  const createMutation = useMutation<
    Array<LiteOrderRequisition>,
    DefaultError,
    LiteCreateRequisitionRequest
  >({
    mutationKey: ["createRequisition"],
    mutationFn: createRequisition,
    onSuccess: async (result) => {
      // TODO: data on success screen should be taken from mutation cache.
      dispatch({
        type: "setLastCreated",
        value: result,
      });
      clearOrderRequisitionFlow();

      queryClient.setQueryData(
        // New requisitions are not closed, so this simplification is enough
        isCloseAttributeEnabled ? ["orderRequisitionsList", false] : ["orderRequisitionsList"],
        prependToOrderRequisitionsList(result)
      );

      await handleDraftRemoval();

      if (areOfflineCapabilitiesEnabled) {
        const [createdRequisition] = result;
        await ordersStore.put(createdRequisition, createdRequisition.id);
      }

      navigate(RoutesConfig.requisitions.success);
    },
    onError: (error: unknown) =>
      // error
      {
        if (isApiError(error)) {
          if (error.status === 400) {
            setValidationErrorMessage(error.body?.errors?.[0]?.defaultMessage);
          }
        }
        setToastMessage({
          type: "failure",
          // Here should be error message but there is no such thing in types nor documentation. To do in BE.
          message: "There is an error with creating a requisition.",
        });
      },
  });

  const submitRequisition = async (requisitionSummary: SubmitRequisitionFormData) => {
    const validatedOrderData = validateOrderWithSideEffects();

    if (!validatedOrderData) {
      // Validation failed & all side effects have been triggered
      return;
    }

    const draftId = isDraftValid && !isOfflineDraftEnabled ? draft.id : undefined;

    const request: LiteCreateRequisitionRequest = {
      draftId,
      // From validated order data
      vesselId: validatedOrderData.vessel.id,
      supplierId: validatedOrderData.supplierId,
      catalogItems: validatedOrderData.catalogItems,
      extraItems: validatedOrderData.extraItems,
      portId: validatedOrderData.port.id,
      orderType: validatedOrderData.orderType,
      rfqItems: validatedOrderData.rfqItems,
      // Directly from app state
      ...(appState.dutyFreeDeclaration
        ? {
            dutyFreeDeclaration: {
              dutyFree: appState.dutyFreeDeclaration.dutyFree,
              name: appState.dutyFreeDeclaration.name,
              position: appState.dutyFreeDeclaration.position,
            },
          }
        : {}),
      // From local form data
      requesterInformation: requisitionSummary.requesterInformation,
      agentInformation: requisitionSummary.agentInformation,
      deliveryDate: requisitionSummary.deliveryDate,
      customerOrderId: requisitionSummary.customerOrderId,
      storageLabel: requisitionSummary.storageLabel,
      orderNotes: requisitionSummary.orderNotes,
      invoiceAccountId: requisitionSummary.invoiceAccountId,
      consolidated: requisitionSummary.consolidated,
      warehouseId: requisitionSummary.consolidated ? requisitionSummary.warehouseId : undefined,
      subject:
        requisitionSummary.subject ||
        getDefaultOrderName(appState.port, requisitionSummary.deliveryDate),
      // Basket answers form
      basketFormAnswers: basketAnswersForm,
    };

    const skus = await productVariantsAsyncMutation({
      variantIds: lineItems.map((item) => item.sku.id),
      portId: validatedOrderData.port.id,
      orderType: validatedOrderData.orderType,
    });

    const { productsWithChangedPrice, unavailableProducts } = findChangedProducts({
      lineItems,
      skus,
      submitCallback: () => {
        createMutation.mutate(request);
      },
    });

    if (unavailableProducts.length > 0 || productsWithChangedPrice.length > 0) {
      return;
    }

    createMutation.mutate(request);
  };

  const submitRequisitionHandler = (requisitionSummary: SubmitRequisitionFormData) => {
    allowOnlineOnly(() => {
      submitRequisition(requisitionSummary);
    });
  };

  const renderAttentionInfo = useCallback(
    ({ className }: { className: string }) => {
      const items = [
        ...appState.attentionInfo,
        ...(extraItems.length > 0 ? [orderExtraItemsTermsAttentionInfoItem] : []),
      ];
      return items.length > 0 ? <AttentionInfo items={items} className={className} /> : null;
    },
    [appState.attentionInfo, extraItems.length]
  );

  const updateOrderNameInState = (value: string) => {
    dispatch({
      type: "setOrderName",
      value,
    });
  };

  const content = (
    <>
      <RequisitionInfo
        submitForm={submitRequisitionHandler}
        loading={createMutation.isPending || isProductVariantsMutationPending}
        validationErrorMessage={validationErrorMessage}
        renderAttentionInfo={renderAttentionInfo}
        updateOrderNameInState={updateOrderNameInState}
      />
      <AllowOnlineOnlyWarningModal />
      <BasketVerificationModal />
    </>
  );

  return <RequisitionLayout>{content}</RequisitionLayout>;
};
