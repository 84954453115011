import { z } from "zod";

import { SkuAttachmentTypeSchema } from "./SkuAttachmentTypeSchema";
import { nonEmptyString, optionalNonEmptyString } from "./common";

export const LiteSkuAttachmentSchema = z.object({
  type: SkuAttachmentTypeSchema,
  // At least `d.om`
  attachmentUrl: nonEmptyString({ minLength: 4 }),
  description: optionalNonEmptyString(),
  fileSize: z.number().positive(),
  createdDate: optionalNonEmptyString(),
  name: optionalNonEmptyString(),
});
