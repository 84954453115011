import copy from "copy-to-clipboard";
import { useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Observable, catchError, forkJoin, of } from "rxjs";

import { Heading, Paragraph, RegularButton, Strong } from "@web/ui";
import { connectAndExportIdbData, isError, isStorageError, triggerFileDownload } from "@web/utils";

import { StoreNames, getStoreName } from "src/objectStorage";
import { isApiError, isOfflineCatalogError } from "src/utils";

type DebuggerMenuProps = {
  onClose: () => void;
  errorDescription?: string;
  error?: unknown;
};

const version = (import.meta.env.VITE_GIT_HASH || "") as string;

const excludedStoreNames = [StoreNames.IMAGES];

const getStoresDataObservable = (): Observable<{ [key: string]: unknown }[]> =>
  forkJoin(
    Object.keys(StoreNames).reduce((connections: Promise<{ [key: string]: unknown }>[], key) => {
      const storeNameEnumEntry = StoreNames[key as keyof typeof StoreNames];
      if (!excludedStoreNames.includes(storeNameEnumEntry)) {
        const storeName = getStoreName(storeNameEnumEntry);
        connections.push(connectAndExportIdbData(storeName));
      }
      return connections;
    }, [])
  ).pipe(catchError((error) => of(error)));

const getCatalogsDataObservable = (): Observable<{ [key: string]: unknown }[]> =>
  forkJoin([connectAndExportIdbData("s2s-lite")]).pipe(catchError((error) => of(error)));

export const DebuggerMenu = ({ onClose, errorDescription, error }: DebuggerMenuProps) => {
  const location = useLocation();
  const handleClose = () => {
    onClose();
  };

  const [storesDownloadError, setStoresDownloadError] = useState<unknown>();
  const [catalogsDownloadError, setCatalogsDownloadError] = useState<unknown>();

  const storesData$ = useRef(getStoresDataObservable());
  const catalogsData$ = useRef(getCatalogsDataObservable());

  const readableLocation = location.pathname + location.search;

  const timestamp = useMemo(() => new Date().toISOString(), []);

  const appStatus = useMemo(() => {
    try {
      return {
        timestamp,
        location,
        version,
        localStorage: Object.keys(localStorage).reduce(
          (ls: { [key: string]: string | null }, key) => {
            ls[key] = localStorage.getItem(key);
            return ls;
          },
          {}
        ),
        sessionStorage: Object.keys(sessionStorage).reduce(
          (ls: { [key: string]: string | null }, key) => {
            ls[key] = sessionStorage.getItem(key);
            return ls;
          },
          {}
        ),
        idbDownloadError: storesDownloadError,
        error: {
          description: errorDescription,
          ...(isApiError(error) || isStorageError(error) || isOfflineCatalogError(error)
            ? { status: error.status }
            : {}),
          ...(isError(error) ? { name: error.name, message: error.message } : {}),
          // Coerce to string just in case
          rawErrorData: typeof error === "object" ? error : error + "",
        },
      };
    } catch (error) {
      // Intentionally return no value
      return {
        error: {
          description: "Error while creating the appStatus data",
          rawErrorData: error,
          status: "",
          name: "",
          message: "",
        },
      };
    }
  }, [error, errorDescription, storesDownloadError, location, timestamp]);

  const errorDetails = appStatus.error;

  const stringifiedAppStatus = useMemo(() => {
    try {
      return JSON.stringify(appStatus, null, 2);
    } catch {
      return '{ "error": { "description": "Error while stringifying the appStatus data" }}';
    }
  }, [appStatus]);

  const [isAppStatusCopiedToClipboard, setIsAppStatusCopiedToClipboard] = useState(false);
  const handleCopyAppStatusToClipboard = useCallback(() => {
    if (!stringifiedAppStatus) {
      return;
    }
    copy(stringifiedAppStatus);
    setIsAppStatusCopiedToClipboard(true);
    setTimeout(() => {
      setIsAppStatusCopiedToClipboard(false);
    }, 3000);
  }, [stringifiedAppStatus]);

  const [isAppStatusDownloading, setIsAppStatusDownloading] = useState(false);

  const handleDownloadAppStatus = useCallback(() => {
    if (!stringifiedAppStatus) {
      return;
    }
    const blob = new Blob([stringifiedAppStatus], { type: "text/plain" });
    triggerFileDownload({
      file: blob,
      fileNameWithExtension: `S2S_status_${timestamp}.txt`,
    });
    setIsAppStatusDownloading(true);
    setTimeout(() => {
      setIsAppStatusDownloading(false);
    }, 3000);
  }, [timestamp, stringifiedAppStatus]);

  const [areStoresDownloading, setAreStoresDownloading] = useState(false);
  const handleDownloadStores = useCallback(() => {
    setAreStoresDownloading(true);
    setStoresDownloadError(undefined);
    storesData$.current.subscribe((data) => {
      setTimeout(() => {
        setAreStoresDownloading(false);
      }, 3000);
      try {
        const stringifiedStoresData = JSON.stringify(data, null, 2);
        const blob = new Blob([stringifiedStoresData], { type: "text/plain" });
        triggerFileDownload({
          file: blob,
          fileNameWithExtension: `S2S_stores_${timestamp}.txt`,
        });
      } catch (error) {
        setStoresDownloadError(error);
      }
    });
  }, [timestamp]);

  const [areCatalogsDownloading, setAreCatalogsDownloading] = useState(false);
  const handleDownloadCatalogs = useCallback(() => {
    setAreCatalogsDownloading(true);
    setCatalogsDownloadError(undefined);
    catalogsData$.current.subscribe((data) => {
      setTimeout(() => {
        setAreCatalogsDownloading(false);
      }, 3000);
      try {
        const stringifiedCatalogsData = JSON.stringify(data, null, 2);
        const blob = new Blob([stringifiedCatalogsData], { type: "text/plain" });
        triggerFileDownload({
          file: blob,
          fileNameWithExtension: `S2S_catalogs_${timestamp}.txt`,
        });
      } catch (error) {
        setCatalogsDownloadError(error);
      }
    });
  }, [timestamp]);

  return (
    <div className="fixed right-0 top-0 bottom-0 w-full sm:w-[26rem] bg-[#031c] z-50 flex">
      <div className="m-auto max-h-screen w-full">
        <div className="h-screen max-h-screen px-6.5 py-6.5 overflow-y-auto">
          <Heading size="200" color="text-textIcon-whitePrimary">
            Customer Support Menu
          </Heading>
          <Paragraph size="200" color="text-textIcon-whitePrimary" className="mt-2">
            If you see this menu but were not asked to open it by the Source2Sea Customer Support
            team, <Strong>please close it now</Strong> by pressing the &quot;Close Menu&quot; button
            below:
          </Paragraph>
          <div className="flex justify-center mt-3">
            <RegularButton
              variant="secondary"
              size="large"
              label="Close Menu"
              onClick={handleClose}
            />
          </div>
          <pre className="text-textIcon-whitePrimary whitespace-pre-wrap mt-6">
            <strong>Timestamp:</strong> {appStatus.timestamp}
            <br />
            <strong>Version:</strong> {appStatus.version}
            <br />
            <strong>Location:</strong>
            <br />
            {readableLocation}
            <br />
            <br />
            {!!errorDetails?.description && (
              <>
                <strong>Error description:</strong>
                <br />
                {errorDetails.description}
                <br />
                <br />
              </>
            )}
            {!!errorDetails?.status && (
              <>
                <strong>Error status:</strong> {errorDetails.status}
                <br />
              </>
            )}
            {!!errorDetails?.name && (
              <>
                <strong>Error name:</strong> {errorDetails.name}
                <br />
              </>
            )}
            {!!errorDetails?.message && (
              <>
                <strong>Error message:</strong>
                <br />
                {errorDetails.message}
              </>
            )}
          </pre>

          <Paragraph size="200" color="text-textIcon-whitePrimary" className="my-6">
            Only use the buttons below if directly asked by the Source2Sea Customer Support.
          </Paragraph>

          {!!stringifiedAppStatus && (
            <div className="flex flex-col items-center mb-6 gap-3">
              <RegularButton
                variant="secondary"
                size="small"
                label={
                  isAppStatusCopiedToClipboard
                    ? "Copied to clipboard!"
                    : "Copy app status to clipboard"
                }
                disabled={isAppStatusCopiedToClipboard}
                onClick={handleCopyAppStatusToClipboard}
              />
              <RegularButton
                variant="secondary"
                size="small"
                label="Download app status"
                loading={isAppStatusDownloading}
                onClick={handleDownloadAppStatus}
              />
            </div>
          )}
          <div className="flex flex-col items-center gap-3">
            <RegularButton
              variant="secondary"
              size="small"
              label="Download stores data"
              loading={areStoresDownloading}
              onClick={handleDownloadStores}
            />
            {!!storesDownloadError && (
              <Paragraph size="300" color="text-textIcon-whitePrimary">
                There was an error when downloading the Stores Data. It was logged in the App
                Status. Please try again.
              </Paragraph>
            )}
            <RegularButton
              variant="secondary"
              size="small"
              label="Download Catalogs Data"
              loading={areCatalogsDownloading}
              onClick={handleDownloadCatalogs}
            />
            {!!catalogsDownloadError && (
              <Paragraph size="300" color="text-textIcon-whitePrimary">
                There was an error when downloading the Catalogs Data. It was logged in the App
                Status. Please try again.
              </Paragraph>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
