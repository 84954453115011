import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate, useParams } from "react-router-dom";

import { DeleteOrderDraftModal } from "@web/common/components/modals/DeleteOrderDraftModal";
import { OrderContextMenuActions, useModalContext } from "@web/common/contexts/ModalContext";
import { Loading } from "@web/ui";
import { triggerFileDownload } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import { useDeleteOrderDraftMutation } from "src/hooks/orderDrafts/useDeleteOrderDraftMutation";
import {
  useOrderDraftsQuery,
  useOrderDraftsQueryHelpers,
} from "src/hooks/orderDrafts/useOrderDraftsQuery";
import { useExportBasketToExcelMutation } from "src/hooks/useExportBasketToExcelMutation";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useToastMessage } from "src/hooks/useToastMessage";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";

import { OrderDetails } from "./components";

export const OrderDraftDetailsController: React.FC = () => {
  const { id } = useParams() as { id: string };

  const { closeModal, openModal } = useModalContext();

  const query = useOrderDraftsQuery();
  const { getDraftById } = useOrderDraftsQueryHelpers();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const { setToastMessage } = useToastMessage();
  const hasSelectSupplierFeature = useFlag("select-supplier");

  const navigate = useNavigate();
  const { mutate: deleteDraft, isPending: isDeletingDraft } = useDeleteOrderDraftMutation({
    hasSuccessMessage: true,
    hasErrorMessage: true,
    onSuccess: () => {
      closeModal();
      navigate(RoutesConfig.order.overview);
    },
  });

  const { mutate: downloadExcel } = useExportBasketToExcelMutation({
    onSuccess: (file: Blob) => {
      setToastMessage({
        type: "success",
        message: "The draft will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${id}.xlsx`,
      });
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "There was an issue while downloading the draft. Please try again.",
      });
    },
  });

  const onDeleteDraftConfirm = (draftId: string) => {
    deleteDraft(draftId);
  };

  const openDeleteDraftModal = (draftId: string) => {
    openModal(
      <DeleteOrderDraftModal
        closeModal={closeModal}
        draftId={draftId}
        isLoading={isDeletingDraft}
        onConfirm={() => onDeleteDraftConfirm(draftId)}
      />
    );
  };

  if (query.isPending || query.isFetching) {
    return <Loading />;
  }

  const draft = getDraftById(query.data || [], id);

  if (!draft) {
    return <div>Draft not found</div>;
  }

  const draftAsOrder = LocalOrderReqService.toOrderRequisition(draft);

  const handleDeleteDraft = () => {
    openDeleteDraftModal(draftAsOrder.id);
  };

  const handleExcelDownload = () => {
    allowOnlineOnly(() => {
      downloadExcel({
        orderType: draftAsOrder.orderType,
        portId: draftAsOrder.port.id,
        supplierId: hasSelectSupplierFeature ? draftAsOrder.supplierInformation.id : undefined,
        items: draftAsOrder.items,
        extraItems: draftAsOrder.extraItems,
      });
    });
  };

  const contextMenuActions: OrderContextMenuActions = {
    onDeleteDraft: handleDeleteDraft,
    onExcelDownload: handleExcelDownload,
  };

  return (
    <>
      <OrderDetails order={draftAsOrder} contextMenuActions={contextMenuActions} />
      <AllowOnlineOnlyWarningModal />
    </>
  );
};
