import { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import { ActionBar, Loading, Modal } from "@web/ui";

import { NoCatalogDataModal } from "src/components/Modal/NoCatalogDataModal";
import { OldCatalogDataModal } from "src/components/Modal/OldCatalogDataModal";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useNetworkToggle } from "src/contexts/NetworkToggle";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useOfflineCatalogMetaData } from "src/hooks/useOfflineCatalogMetaData";
import { useToastMessage } from "src/hooks/useToastMessage";
import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import AppState, { Configuration } from "src/state/models";
import { getCompositeStoredCatalogId, isCatalogDataOld } from "src/utils";
import trackingCategories from "src/utils/trackingCategories";
import trackingEvents from "src/utils/trackingEvents";

import { PreconfigureOrderForm } from "./PreconfigureOrderForm";
import { FormValues, ValidatedForm } from "./models";

// Validity of the provided values (if supplier exists in port, and port in order type)
// is guaranteed by business logic existing in the AppState reducer.
// We only need to preselect a value if there is only one value to choose from.
const getPreselectedFormValues = ({
  orderTypeConfigs,
  orderType,
  port,
  supplier,
}: {
  orderTypeConfigs: Configuration["orderTypeConfigs"] | undefined;
  orderType: AppState["orderType"];
  port: AppState["port"];
  supplier: AppState["supplier"];
}): {
  preselectedOrderType: FormValues["orderType"] | undefined;
  preselectedPort: FormValues["port"] | undefined;
  preselectedSupplier: FormValues["supplier"] | undefined;
} => {
  const preselectedOrderType =
    LocalConfigurationService.getSelectedOrderTypeConfig({
      orderTypeConfigs,
      orderType,
    }) || (orderTypeConfigs?.length === 1 ? orderTypeConfigs[0] : undefined);

  const preselectedPort =
    LocalConfigurationService.getSelectedPortConfig({
      orderTypeConfigs,
      orderType,
      portId: port?.id,
    }) || (preselectedOrderType?.ports.length === 1 ? preselectedOrderType.ports[0] : undefined);

  const preselectedSupplier =
    LocalConfigurationService.getSelectedSupplierConfig({
      orderTypeConfigs,
      orderType,
      portId: port?.id,
      supplierId: supplier?.id,
    }) || (preselectedPort?.suppliers.length === 1 ? preselectedPort.suppliers[0] : undefined);

  return {
    preselectedOrderType,
    preselectedPort,
    preselectedSupplier,
  };
};

export const PreconfigureOrder = () => {
  const [{ configuration, port, orderType, supplier, isPunchoutSession }, dispatch] =
    useAppStateContext();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const [isOldCatalogDataMsgShown, setIsOldCatalogDataMsgShown] = useState(false);
  const [isNoCatalogDataMsgShown, setIsNoCatalogDataMsgShown] = useState(false);
  const [offlineCatalogUpdatedAt, setOfflineCatalogUpdatedAt] = useState("");
  const [isPending, setIsPending] = useState(false);

  const [formData, setFormData] = useState<ValidatedForm | undefined>(undefined);
  const { isOffline, isOnline, forceOffline } = useNetworkDetector();
  const { setToggleOffline } = useNetworkToggle();
  const { data: offlineCatalogMetadata, isPending: isOfflineCatalogMetadataPending } =
    useOfflineCatalogMetaData();
  const navigate = useNavigate();
  const { setToastMessage } = useToastMessage();

  const { preselectedOrderType, preselectedPort, preselectedSupplier } = useMemo(
    () =>
      getPreselectedFormValues({
        orderTypeConfigs: configuration?.orderTypeConfigs,
        orderType,
        port,
        supplier,
      }),
    [configuration?.orderTypeConfigs, orderType, port, supplier]
  );

  const commitFormData = useCallback(
    (form?: ValidatedForm) => {
      if (!form) {
        throw new Error("PreconfigureOrderSetup - no form data");
      }
      // This is just a failsafe, because we had some weird issues with missing data
      if (!form.orderType || !form.port || !form.supplier) {
        setToastMessage({
          type: "failure",
          message:
            "There was a problem with starting the order - missing Order Type, Port or Supplier. Please contact Customer Support.",
        });
        return;
      }

      setIsPending(true);

      ReactGA.event({
        category: trackingCategories.CREW_APP,
        action: trackingEvents.PORT_SELECTED,
        label: form.port.name,
      });
      dispatch({
        type: "setPreconfigureOrderSetup",
        value: {
          portId: form.port.id,
          orderType: form.orderType.type,
          supplierId: form.supplier.id,
          deliveryDate: form.deliveryDate,
          dutyFreeDeclaration: form.dutyFreeDeclaration,
          orderName: form.orderName,
        },
      });
    },
    [dispatch, setToastMessage]
  );

  const openOldCatalogDataModal = useCallback((formData: ValidatedForm) => {
    setFormData(formData);
    setIsOldCatalogDataMsgShown(true);
  }, []);

  const openNoCatalogDataModal = useCallback((formData: ValidatedForm) => {
    setFormData(formData);
    setIsNoCatalogDataMsgShown(true);
  }, []);

  const handleFormSubmit = useCallback(
    (form: ValidatedForm) => {
      const storedCatalogId = getCompositeStoredCatalogId({
        orderType: form.orderType.type,
        portId: form.port.id,
        supplierId: form.supplier.id,
      });

      const offlineCatalogUpdatedAt = offlineCatalogMetadata?.get(storedCatalogId)?.updatedAt || "";
      setOfflineCatalogUpdatedAt(offlineCatalogUpdatedAt);

      if (areOfflineCapabilitiesEnabled && isOffline && isCatalogDataOld(offlineCatalogUpdatedAt)) {
        openOldCatalogDataModal(form);
      } else if (areOfflineCapabilitiesEnabled && isOffline && !offlineCatalogUpdatedAt) {
        openNoCatalogDataModal(form);
      } else {
        commitFormData(form);
      }
    },
    [
      areOfflineCapabilitiesEnabled,
      commitFormData,
      isOffline,
      offlineCatalogMetadata,
      openNoCatalogDataModal,
      openOldCatalogDataModal,
    ]
  );

  useEffect(() => {
    // After old/no catalog data warning was displayed & user's machine successfully reconnected
    // to the internet in the background:
    // 1) submit form data
    // 2) dismiss the modal
    // 3) redirect user to Gather Experience
    if ((isOldCatalogDataMsgShown || isNoCatalogDataMsgShown) && isOnline && formData) {
      commitFormData(formData);
      setIsOldCatalogDataMsgShown(false);
      setIsNoCatalogDataMsgShown(false);
    }
  }, [
    commitFormData,
    forceOffline,
    formData,
    isNoCatalogDataMsgShown,
    isOldCatalogDataMsgShown,
    isOnline,
    setToggleOffline,
  ]);

  if (!configuration || isOfflineCatalogMetadataPending) {
    return <Loading />;
  }

  return (
    <>
      <Modal
        isOpen={isOldCatalogDataMsgShown}
        closeModal={() => setIsOldCatalogDataMsgShown(false)}
      >
        <OldCatalogDataModal
          isReconnecting={false}
          onClose={() => setIsOldCatalogDataMsgShown(false)}
          onContinueOffline={() => {
            setIsOldCatalogDataMsgShown(false);
            commitFormData(formData);
          }}
          onSwitchToOnline={() => {
            /* noop */
          }}
          isManualNetworkStateEnabled={false}
          catalogLastUpdated={offlineCatalogUpdatedAt}
        />
      </Modal>
      <Modal isOpen={isNoCatalogDataMsgShown} closeModal={() => setIsNoCatalogDataMsgShown(false)}>
        <NoCatalogDataModal
          isReconnecting={false}
          onClose={() => setIsNoCatalogDataMsgShown(false)}
          onSwitchToOnline={() => {
            /* noop */
          }}
          isManualNetworkStateEnabled={false}
        />
      </Modal>
      <div className="flex flex-col min-h-full items-center bg-neutral_100">
        <TopBarController />
        <ActionBar
          backButtonSettings={
            isPunchoutSession
              ? undefined
              : {
                  title: "Return to Overview",
                  onClick: () => {
                    navigate(RoutesConfig.mainPage);
                  },
                }
          }
        />
        <PreconfigureOrderForm
          onSubmitForm={handleFormSubmit}
          orderTypeConfigs={configuration.orderTypeConfigs}
          preselectedOrderType={preselectedOrderType}
          preselectedPort={preselectedPort}
          preselectedSupplier={preselectedSupplier}
          isPunchOutIntegration={isPunchoutSession}
          isPending={isPending}
        />
      </div>
    </>
  );
};
