import { useQuery } from "@tanstack/react-query";

import { LiteOrderTypeConfiguration, LiteProductSku, ProductsService } from "../typegens";

export const PRODUCT_VARIANTS_QUERY_KEY_BASE = "productVariants";

export const useProductVariantsQuery = ({
  variantIds,
  portId,
  orderType,
}: {
  variantIds: string[];
  portId: string;
  orderType: LiteOrderTypeConfiguration["type"];
}) =>
  useQuery<LiteProductSku[]>({
    queryKey: [PRODUCT_VARIANTS_QUERY_KEY_BASE, portId, variantIds, orderType],
    queryFn: async ({ signal }) => {
      const response = await ProductsService.getProductVariants(
        {
          requestBody: {
            portId,
            variantIds,
            orderType,
          },
        },
        { signal }
      );
      return response.items;
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
  });
