import { z } from "zod";

import { LiteImageSchema } from "../LiteImageSchema";
import { LitePriceSchema } from "../LitePriceSchema";
import { LiteSkuAboutSchema } from "../LiteSkuAboutSchema";
import { LiteSkuAttachmentSchema } from "../LiteSkuAttachmentSchema";
import { LiteSkuCareAndUseSchema } from "../LiteSkuCareAndUseSchema";
import { LiteSkuDimensionsAndDeliverySchema } from "../LiteSkuDimensionsAndDeliverySchema";
import { LiteSkuManufactureSchema } from "../LiteSkuManufactureSchema";
import { LiteSkuStorageInformationSchema } from "../LiteSkuStorageInformationSchema";
import { nonEmptyString, optionalNonEmptyString } from "../common";
import { LiteStocktakeSkuSupplierInformationSchema } from "./LiteStocktakeSkuSupplierInformationSchema";

export const LiteStocktakeSkuDetailsSchema = z.object({
  measurementUnit: nonEmptyString(),
  salesEntityPrice: LitePriceSchema,
  salesEntityQuantity: z.number().positive(),
  dimensionsAndDelivery: LiteSkuDimensionsAndDeliverySchema.optional(),
  storage: LiteSkuStorageInformationSchema.optional(),
  careAndUse: LiteSkuCareAndUseSchema.optional(),
  manufacture: LiteSkuManufactureSchema.optional(),
  supplierInformation: LiteStocktakeSkuSupplierInformationSchema,
  images: z.array(LiteImageSchema),
  about: LiteSkuAboutSchema,
  attachments: z.array(LiteSkuAttachmentSchema),
  buyerItemId: optionalNonEmptyString(),
  leadTime: optionalNonEmptyString(),
});
