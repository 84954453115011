/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListLiteProduct } from "../models/ApiListLiteProduct";
import type { ApiListLiteProductSku } from "../models/ApiListLiteProductSku";
import type { LiteProduct } from "../models/LiteProduct";
import type { LiteProductVariantsRequest } from "../models/LiteProductVariantsRequest";

export class ProductsService {
  /**
   * @returns ApiListLiteProductSku OK
   * @throws ApiError
   */
  public static getProductVariants(
    {
      requestBody,
    }: {
      requestBody: LiteProductVariantsRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteProductSku> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/lite/products/variants",
      body: requestBody,
      mediaType: "application/json",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns LiteProduct OK
   * @throws ApiError
   */
  public static getProductSku(
    {
      id,
      portId,
      supplierId,
      orderType,
    }: {
      id: string;
      portId: string;
      supplierId?: string;
      orderType?: "BONDED" | "PROVISIONS" | "STORES" | "WELFARE" | "DEFAULT";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<LiteProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/products/{id}",
      path: {
        id: id,
      },
      query: {
        portId: portId,
        supplierId: supplierId,
        orderType: orderType,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListLiteProduct OK
   * @throws ApiError
   */
  public static productsSearch(
    {
      portId,
      query,
      sort,
      priceMin,
      priceMax,
      leadTime,
      brand,
      cursor,
      supplierId,
      orderType,
    }: {
      portId: string;
      query: string;
      sort?: "PRICE_ASC" | "PRICE_DESC";
      priceMin?: string;
      priceMax?: string;
      leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
      brand?: string;
      cursor?: string;
      supplierId?: string;
      orderType?: "BONDED" | "PROVISIONS" | "STORES" | "WELFARE" | "DEFAULT";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/products/search",
      query: {
        portId: portId,
        query: query,
        sort: sort,
        priceMin: priceMin,
        priceMax: priceMax,
        leadTime: leadTime,
        brand: brand,
        cursor: cursor,
        supplierId: supplierId,
        orderType: orderType,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListLiteProduct OK
   * @throws ApiError
   */
  public static getProductsByCategoryId(
    {
      portId,
      categoryId,
      sort,
      priceMin,
      priceMax,
      leadTime,
      brand,
      cursor,
      supplierId,
      orderType,
    }: {
      portId: string;
      categoryId: string;
      sort?: "PRICE_ASC" | "PRICE_DESC";
      priceMin?: string;
      priceMax?: string;
      leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
      brand?: string;
      cursor?: string;
      supplierId?: string;
      orderType?: "BONDED" | "PROVISIONS" | "STORES" | "WELFARE" | "DEFAULT";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListLiteProduct> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/lite/products/category/{categoryId}",
      path: {
        categoryId: categoryId,
      },
      query: {
        portId: portId,
        sort: sort,
        priceMin: priceMin,
        priceMax: priceMax,
        leadTime: leadTime,
        brand: brand,
        cursor: cursor,
        supplierId: supplierId,
        orderType: orderType,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
